<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="emails-status-modal">
      <div class="emails-status-container">
        <div class="close-btn-wrapper">
          <v-btn class="close-btn" @click="$router.go(-1)">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
          <v-btn class="close-btn sync mt-2 icon-with-hover" @click="syncData">
            <v-icon class="close-modal-icon">fa-sync</v-icon>
          </v-btn>
        </div>
        <v-col sm="12" class="full-width">
          <v-row class="search-wrap">
            <v-col align="center">
              <div class="page-title my-3">{{ $t('E-mails') }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-card class="main-card" flat>
          <v-container>
            <div>
              <v-row align="center" class="form-container justify-center pb-4">
                <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
                <template v-else-if="!error">
                  <v-row class="mx-0">
                    <v-col cols="10">
                      <v-text-field
                        v-model="search"
                        append-icon="fa-magnifying-glass"
                        :label="$t('Search')"
                        hide-details
                        @input="searchHandler"
                        class="mb-3"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        class="select-input "
                        hide-details="true"
                        v-model="statusFilter"
                        :items="statusItems"
                        append-icon="fa-angle-down"
                        :label="$t('Email status')"
                        @input="searchHandler"
                      />
                    </v-col>
                  </v-row>
                  <v-col sm="12" v-if="articlesView.length" class="pb-16">
                    <v-data-table
                      :headers="headers"
                      :items="articlesView"
                      :items-per-page="itemPerPage"
                      class="pb-16 pb-sm-0"
                      item-class="edit-article-row"
                      sort-by="id"
                      must-sort
                      sort-desc
                      ref="table"
                      :no-data-text="$t('No bid transactions found')"
                      :key="key"
                      dense
                      :footer-props="{
                        'items-per-page-all-text': $t('All'),
                        'items-per-page-text': $t('Rows per page'),
                        'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                        'items-per-page-options': [50, 100, 150, 200]
                      }"
                    >
                      <template #[`item.created_at`]="{ item }">
                        <span class="text-light">{{ getDate(item.created_at) }}</span>
                      </template>
                      <template #[`item.email_code`]="{ item }">
                        <v-icon v-if="item.email_code === 200" class="green-color-success">fa-check</v-icon>
                        <v-icon v-else class="red-color">fa-times</v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                  <div class="full-width mt-6" v-else>
                    <div class="info-text" style="max-width: 300px;">
                      <v-icon>fa-circle-info</v-icon>
                      <p>{{ $t('No emails found') }}</p>
                    </div>
                  </div>
                </template>
                <v-col sm="12" class="text-center" v-else>
                  <h3 class="no-data-text">{{ $t(error) }}</h3>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <Footer />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import Footer from '@/components/footer/index'
import apiRequests from '@/api/apiRequests'
import _ from 'lodash'
import { timeouts } from '@/utils/constants'
import useRootStore from '@/stores/rootStore'
import { euNumbers } from '@/services/i18n';

export default {
  name: 'BidTransactions',
  components: {
    Footer,
  },
  data: function () {
    return {
      renderComponent: true,
      search: null,
      statusFilter: 'all',
      statusItems: [
        {
          value: 'all',
          text: this.$t('All')
        },
        {
          value: 200,
          text: this.$t('Successful')
        },
        {
          value: 400,
          text: this.$t('Error')
        }
      ],
      headers: [
        {
          text: this.$t('Created at'),
          value: 'created_at',
          sortable: false,
          width: 240
        },
        {
          text: this.$t('ID'),
          value: 'id',
          width: 70
        },
        {
          text: this.$t('User'),
          value: 'user_data',
          width: 200,
          sortable: false
        },
        {
          text: this.$t('Mail template'),
          value: 'template',
          width: 200
        },
        {
          text: this.$t('Language'),
          value: 'language',
          width: 20,
          sortable: false
        },
        {
          text: this.$t('Result'),
          value: 'email_code',
          sortable: false,
          width: 20
        },
        {
          text: this.$t('Error'),
          value: 'email_error',
          sortable: false
        }
      ],
      itemPerPage: 50,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      tmpArticles: [],
      loading: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['appStoreLang']),
    articlesView() {
      return this.filterByStatus(this.tmpArticles)
    },
  },
  created() {
    this.getEmails()
  },
  methods: {
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    async getEmails() {
      try {
        this.loading = true
        const usersResp = await apiRequests.getUsers()
        const emailsResp = await apiRequests.getEmailsStatus()
        const emails = emailsResp.data.data.map(email => {
          const user = usersResp.data.data.find(user => email.users_id === user.id)
          return {
            ...email,
            user_data: `${user.last_name} ${user.first_name} (${user.id})`
          }
        })
        this.articles = emails.sort((a, b) => b.id - a.id)
        this.tmpArticles = [...this.articles]
        this.loading = false
      } catch (e) {
        this.loading = false
        this.error = this.$t('There was an error fetching data')
      }
    },

    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
    },

    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
    },

    syncData() {
      this.getEmails()
    },

    searchHandler: _.debounce(function () {
      if (this.search) {
        this.tmpArticles = this.filterByStatus(this.articles).filter(el => (el.user_data && el.user_data.toLowerCase().includes(this.search.toLowerCase())) ||
          (el.email_error && el.email_error.toLowerCase().includes(this.search.toLowerCase())))
      } else {
        this.tmpArticles = [...this.filterByStatus(this.articles)]
      }
    }, timeouts.debounce),

    filterByStatus(allArticles) {
      if (this.statusFilter !== 'all') {
        return allArticles.filter(el => el.email_code === this.statusFilter)
      }
      return allArticles
    }
  }
}
</script>
<style lang="scss" scoped>
.emails-status-container {
  .close-btn-wrapper {
    position: fixed;
    top: 110px;
    right: 40px;
    z-index: 1;

    .v-ripple__container {
      display: none !important;
    }

    @media(max-width: 968px) {
      top: 90px;
      right: 10px;
    }

    .close-text {
      @media(max-width: 968px) {
        display: none;
      }
    }

    .close-modal-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: all .3s ease;
    }

    button {
      text-transform: none;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      background-color: transparent !important;
      box-shadow: none;
      letter-spacing: unset !important;
      @media(max-width: 968px) {
        width: 40px;
        min-width: 40px !important;
        height: 40px;
        padding: 0 !important;
      }

      &:before, &:focus:before, &:hover:before {
        background-color: transparent !important;
      }

      i {
        @media(max-width: 968px) {
          font-size: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}
.page-title {
  font-size: 24px;
  line-height: 25px;
  font-weight: 400;
}
</style>
