import httpClient from '@/utils/httpClient';
import * as Sentry from '@sentry/vue';
import { i18n } from '@/main';
import { getConnectedUser } from '@/utils/token';
import {saveAs} from 'file-saver';

const apiRequests = {
  getCurrentActiveAuction: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('active-auction-live')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  getAuctionArticles: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  doBid: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/auctions/${data.auctionId}/articles/${data.articleId}/bid`, {
          bid: data.bid,
          type: data.type,
          timestamp: Date.now()
        })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  downloadImages: async (id: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`/auctions/${id}/images`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  doAction: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/auctions/${data.auctionId}/action`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  jumpToArticle: async (id: number, auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/auctions/${auctionId}/articles/${id}/jump-to-article`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  getKnockdowns: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/knockdowns`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  removeCommissionBids: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles/all/commission-bid`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  updateCommissionBids: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/all/commission-bid-status`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  signUp: async (data: any) =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/sign-up', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  confirmUserRegistration: async (data: any) =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/confirmation-account', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  resetPassword: async (data: any) =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/request-reset-password', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  resendActivationEmail: async (data: any) =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/request-resend-activation-email', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  confirmResetPassword: async (data: any) =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/reset-password', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),
  logout: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auth/logout', null, null, 200)
        .then(rs => {
          // Remove Authorization header
          httpClient.removeHeader('Authorization')
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getUserProfile: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/me/profile')
        .then(rs => {
          // Change system language
          httpClient.setHeaderLang(rs.data.data.language)
          i18n.locale = rs.data.data.language
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getNotification: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${getConnectedUser()}/notifications`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  markReadNotification: async (id: number): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`/users/${getConnectedUser()}/notifications/${id}/mark-read`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateUserProfile: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('users/me/profile', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getUserSettings: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/configs?fields=builtinStreaming,general,localization,mailing,hotkeys,features,currencies')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getSystemCurrencies: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/configs?fields=currencies')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAppSettings: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('app-settings?fields=general,localization,builtinStreaming,features')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getArticles: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${id}/articles`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  searchArticles: async (q: string, lang: string): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('articles?query=' + q + "&lang=" + lang)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  checkIfAuctionArticlesUpdated: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${id}/articles`, { getLastUpdatedTimestamp: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getGuestArticles: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`guest/auctions/${id}/articles`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getStatistics: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${id}/stats`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getInquiries: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('admin/inquiries')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getArticlesDynamic: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${id}/articles?dynamicData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getGuestArticlesDynamic: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`guest/auctions/${id}/articles?dynamicData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAllTimedStatus: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${id}/articles/all/timed-status`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getArticleTimedStatus: async (articleId: any, auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles/${articleId}/timed-status`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  placeTimedBid: async (articleId: any, auctionId: any, bid: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/${articleId}/timed-bid`, { maximum_bid: +bid })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getCategories: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`categories/${auctionId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAllCategories: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('categories')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAllGuestCategories: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('guest/categories')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  checkIfCategoriesUpdated: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('categories', { getLastUpdatedTimestamp: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getWishList: async (auctionId: any, userId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/auctions/${auctionId}/wishlist`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getBidTransactions: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${id}/bid-transactions`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getConfigFields: async (fields: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs?fields=${fields}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getEmailTemplate: async (name: any, lang: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs/mail-templates?name=${name}&lang=${lang}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateConfigs: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('admin/configs', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateEmailTemplate: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('admin/configs/mail-templates', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  instantBuy: async (articleId: any, auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/${articleId}/instant-buy`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getEmailTemplates: async (fields: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs?fields=${fields}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  // Admin requests

  uploadImage: async (data: any, headers: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/upload-image', data, headers)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getStreamStats: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('stream/stats')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getVersions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('status/versions')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('status')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getStaticPage: async (pageName: any, lang: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/configs/static-pages?name=${pageName}&lang=${lang}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  sendTestEmail: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/test-email', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getLogs: async (itemPerPage: any, skip: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`admin/logs?take=${itemPerPage}&skip=${skip}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAuctionData: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateImageCache: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`admin/update-image-cache/${auctionId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  validateImages: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/validate-images`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  testMediaServer: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/test-media-server', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  // User requests

  setCommissionBids: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`/auctions/${auctionId}/articles/all/commission-bid`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  validateCommissionBids: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`/auctions/${auctionId}/articles/all/validate-commission-bids`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> => {
    if (getUserData) {
      return new Promise((resolve, reject) => {
        httpClient
          .get(`auctions/${auctionId}/articles/all/commission-bid?getUserData=1`)
          .then(rs => {
            resolve(rs);
          })
          .catch(err => {
            if (err.response?.data) {
              Sentry.setContext('http error data', {
                data: JSON.stringify(err.response.data)
              });
            }
            Sentry.captureException(err);
            reject(err.response);
          });
      })
    } else {
      return new Promise((resolve, reject) => {
        httpClient
          .get(`auctions/${auctionId}/articles/all/commission-bid`)
          .then(rs => {
            resolve(rs);
          })
          .catch(err => {
            if (err.response?.data) {
              Sentry.setContext('http error data', {
                data: JSON.stringify(err.response.data)
              });
            }
            Sentry.captureException(err);
            reject(err.response);
          });
      })
    }
  },
  getAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('auctions')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getGuestAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('guest/auctions')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getUserKnockdowns: async (auctionID: any, userId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionID}/knockdowns/${userId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  sendEmailInquiry: async (userId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/email-inquiry`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  requestSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<any> => {
    let payload = {}
    if (bidLimit !== null) {
      payload = {
        bidLimit: bidLimit
      };
    }
    if (receiveStartReminderEmail !== undefined) {
      payload = {
        ...payload,
        notificationAuctionStart: receiveStartReminderEmail
      }
    }
    return new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/auctions/${auctionId}/request-signup`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    })
  },

  getUserBidderNumbers: async (userId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/bidder-numbers`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getUserBidderNumbersWithState: async (userId: any, state: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/bidder-numbers?state=${state}&getUserData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAuctionBidderNumbersWithState: async (auctionId: any, state: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/users/${state}?getUserToAuctionData=true`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateUserBidderNumbers: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put('users/all/bidder-numbers', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeFromWishList: async (auctionId: any, userId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId}/auctions/${auctionId}/wishlist/${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  addToWishList: async (auctionId: any, userId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/auctions/${auctionId}/wishlist/${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateCategory: async (categoryId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`categories/${categoryId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  createCategory: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('categories', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  deleteCategory: async (categoryId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`categories/${categoryId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getKnockdownsArticleSlider: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/knockdowns-article-slider`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeBidStep: async (auctionId: any, bidStepId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`auctions/${auctionId}/bid-steps/${bidStepId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  createBidStep: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/bid-steps`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateBidStep: async (auctionId: any, bidSteps: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/bid-steps`, { bidSteps, recreate: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateAuction: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  createAuction: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('auctions', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateArticle: async (auctionId: any, articleId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`auctions/${auctionId}/articles/${articleId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  createArticle: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/articles`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeArticle: async (auctionId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`auctions/${auctionId}/articles/${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  importArticles: async (auctionId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/articles/import`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getArticleImages: async (auctionId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`auctions/${auctionId}/articles/${articleId}/images`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  checkIfUsersUpdated: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users', { getLastUpdatedTimestamp: true })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getUsers: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getEmailsStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('admin/emails-sent')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getUserData: async (id: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${id}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeUser: async (userId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  updateUser: async (userId: any, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .put(`users/${userId}`, data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  createUser: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('users', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  importUsers: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('users/import', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  stopArticle: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${data.auctionId}/articles/${data.articleId}/end-timed-article`, { force: data.force })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  startArticle: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${data.auctionId}/articles/${data.articleId}/start-timed-article`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  stopAuction: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${data.auctionId}/stop`, { forceCompleted: data.force })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  startAuction: async (auctionId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`auctions/${auctionId}/start`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getFiles: async (userId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/${userId}/files`, { action: 'list' })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getFilesBackup: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('users/backend/files?action=list&dir=backup')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getFile: async (userId: any, filename: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .getFile(`users/${userId}/files`, { action: `file_${filename}` })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAdminFile: async (filename: any, dir: string, userId: any): Promise<any> => {
    let params: any = {
      action: `file_${filename}`
    }
    if (dir) {
      params = { ...params, dir }
    }
    return new Promise((resolve, reject) => {
      httpClient
        .getFile(`users/${userId || 'backend'}/files`, params)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    })
  },

  exportFiles: async (type: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('/admin/configuration', { type })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  importAdminFiles: async (type: any, file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .postFile(`/admin/configuration?type=${type}`, file)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  restoreAdminFile: async (filename: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('/admin/restoreConfiguration', { filename })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  sendFiles: async (userId: any, payload: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`users/${userId}/files`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeFile: async (userId: any, filename: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId}/files?action=file_${filename}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeAdminFile: async (filename: any, dir: string, userId: any): Promise<any> => {
    let params = `action=file_${filename}`
    if (dir) params += '&dir=backup'
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`users/${userId || 'backend'}/files?${params}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    })
  },

  disconnectUser: async (userId: any, block: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('admin/disconnectClient', { userId, block })
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  loginWithFacebook: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get('auth/login-facebook')
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  signIn: async (email: string, password: string) =>
    new Promise((resolve, reject) => {
      let postData = {
        email,
        password
      };

      httpClient
        .post('auth/login', postData)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getArticleFiles: async (auctionId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`users/backend/files?auctionId=${auctionId}&articleId=${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  uploadArticleFiles: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .post('users/backend/files-raw', data)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  removeArticleFiles: async (auctionId: any, articleId: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .delete(`/users/backend/files?action=article&auctionId=${auctionId}&articleId=${articleId}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getSingleImage: async (auctionId: any, articleId: any, imageIndex: any): Promise<any> =>
    new Promise((resolve, reject) => {
      httpClient
        .getFile(`/users/backend/files?action=download&auctionId=${auctionId}&articleId=${articleId}&imageIndex=${imageIndex}`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  joinLiveAuction: async (auctionId: number, browserTabFingerprint: string) =>
    new Promise((resolve, reject) => {
      const payload = {
        browserTabFingerprint
      };
      httpClient
        .post(`auctions/${auctionId}/join`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  leaveLiveAuction: async (auctionId: number, browserTabFingerprint: string) =>
    new Promise((resolve, reject) => {
      const payload = {
        browserTabFingerprint
      };
      httpClient
        .post(`auctions/${auctionId}/leave`, payload)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  exportUsers: async (filename: string, auctionCode?: number) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(auctionCode !== undefined ? `export/users?auctionCode=${auctionCode}`: `export/users`)
        .then(rs => {
          const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), rs.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, filename);
          resolve(true);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  exportCommissionBids: async (filename: string, auctionCode: number) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`export/commissionBids?auctionCode=${auctionCode}`)
        .then(rs => {
          const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), rs.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, filename);
          resolve(true);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  exportKnockdowns: async (filename: string, auctionCode: number) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`export/knockdowns?auctionCode=${auctionCode}`)
        .then(rs => {
          const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), rs.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, filename);
          resolve(true);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  validateTfa: async (email: string, tfaToken: number) =>
    new Promise((resolve, reject) => {
      let postData = {
        email,
        tfa_code: tfaToken
      };

      httpClient
        .post(`auth/loginTfa`, postData)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    }),

  getAuctionJoinStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {

      httpClient
        .get(`users/me/userJoinStatus`)
        .then(rs => {
          resolve(rs);
        })
        .catch(err => {
          if (err.response?.data) {
            Sentry.setContext('http error data', {
              data: JSON.stringify(err.response.data)
            });
          }
          Sentry.captureException(err);
          reject(err.response);
        });
    })
}

export default apiRequests // eslint-disable-line
