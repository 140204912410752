<template>
  <v-container id="container" class="guest-forms" />
</template>

<script>
import { timeouts } from '@/utils/constants';
import apiRequests from '@/api/apiRequests';

import { mapActions, mapState } from 'pinia'
import useRootStore from '@/stores/rootStore'

export default {
  name: 'RegistrationConfirmationView',
  async mounted() {
    let formData = {
      code: this.$route.query.code,
      email: this.$route.query.email
    }

    this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });

    try {
      await apiRequests.confirmUserRegistration(formData);
      this.$swal.fire({
        position: 'center',
        icon: 'success',
        title: this.$t('Registration completed'),
        html: this.$t('Your registration completed successfully. You can now login'),
        showConfirmButton: false,
        timer: timeouts.closeSweetalert
      })
    } catch (e) {
      this.$swal.fire({
        position: 'center',
        icon: 'error',
        title: this.$t('An error has occured'),
        text: this.$t('The code you tried to use is either expired or was used already'),
        showConfirmButton: false,
        timer: timeouts.closeSweetalert
      })
    }

    this.gotoLogin()
    this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
  },

  data: () => ({
    email: null,
    password: null,
    show: false,
    msg: '',
    toolTipPosition: 'right',
    isError: false,
    isReady: false,
  }),
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appSettings', 'appStoreLang']),
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE']),
    activeButton () {
      return (!this.email || !this.password)
    },
    signUp () {
      this.$router.push({ name: 'registration' })
    },
    gotoLogin () {
      this.$router.push({ name: 'login' })
    }
  },
}
</script>
