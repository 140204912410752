<template>
  <v-app id="app" :class="{ 'auctioneer-view': userProfile && userProfile.role === 'viewer', 'user-view': userProfile && userProfile.role === 'user', 'guest-view': !isAuthenticatedAsUser, activeDrawer: adminDrawer && userProfile.role === 'admin', 'banner-exist': bannerUrl !== '' && ((userProfile && userProfile.role === 'user') || !isAuthenticatedAsUser) }">
    <div class="v-alert__container">
      <v-alert
        v-for="(item, idx) in alert"
        :key="`alert-${idx}`"
        :type="item.flavor"
        :dismissible="item.dismissable"
        dense
        text
        outlined
        border="left"
        :value="!!item"
      >
        {{ item.content }}
      </v-alert>
      <v-alert
        type="error"
        :dismissible="false"
        dense
        text
        outlined
        border="left"
        :value="isAuthenticatedAsUser && isOffline && socketDisconnectReason !== SocketDisconnectReason.ioServerDisonnect"
      >
        {{ $t('You are currently offline due to connectivity issues.\nPlease note that you can use the app in a restricted fashion until the connection is re-established.') }}
      </v-alert>
    </div>
    <!-- Load default APP -->
    <Loader type="overlayer" v-if="!appLoading" />

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column" v-if="$route.name === 'error' || $route.name === 'error-unexpected'">
      <router-view />
    </div>

    <div class="main-router-wrapper" :class="{admin: userProfile.role === 'admin', activeDrawer: adminDrawer && userProfile.role === 'admin', auctionAdmin: $route.name === 'auctionAdmin'}" v-else-if="isLoaded && !$route.meta.plainLayout">
      <Header v-if="isAuthenticatedAsUser" />
      <GuestHeader v-else />
      <router-view />
    </div>

    <div class="app-basic-layout" v-else-if="isLoaded && $route.meta.plainLayout">
      <GuestHeader />
      <keep-alive>
        <router-view />
      </keep-alive>
      <Footer />
    </div>

    <!-- Errors handling -->
    <v-dialog :value="error" max-width="300">
      <v-card class="error-modal">
        <v-card-title class="headline">{{ $t('Error') }}</v-card-title>
        <v-card-text>
          {{ errorMessage }}
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn error-btn" @click="closeErrorModal()">
            {{ $t('Ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <join-modal />
    <vue-cookie-accept-decline
      ref="cookies"
      v-if="_.isEmpty(this.userProfile)"
      elementId="cookies"
      :debug="false"
      position="bottom"
      type="bar"
      :disableDecline="false"
      transitionName="slideFromBottom"
      :showPostponeButton="false"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <div slot="message">
        {{ $t('Cookies are required to make this application work. They are only used for technical purposes and not for tracking, profiling etc. More information can be found') }}
        <a href="javascript:void(0)" @click="$router.push({ name: 'imprint' })">{{ $t('here') }}</a>
      </div>

      <div slot="declineContent">
        {{ $t('Decline') }}
      </div>

      <div slot="acceptContent">
        {{ $t('Accept') }}
      </div>
    </vue-cookie-accept-decline>
  </v-app>
</template>

<script>
import Vue from 'vue'
import Header from '@/components/header/'
import Footer from '@/components/footer/'
import GuestHeader from '@/components/header/guest'
import Loader from '@/components/loader/'
import SocketClient from '@/utils/socket'
import JoinModal from '@/components/modals/user-join-modal.vue'
import * as Sentry from '@sentry/vue';
import { getBidderNumbersFromUser } from '@/services/user';
import {
  AuctionStatusType,
  localFeatures,
  Role,
  SocketConnectionStatus,
  SocketDisconnectReason
} from '@/utils/constants';
import SocketHandlers, { SocketPostHandleEmitterEvent } from '@/api/socketHandlers';
import { i18n } from '@/main';
import _ from 'lodash'
import router from '@/router';
import useAuctionStore from '@/stores/auctionStore';
import useRootStore from '@/stores/rootStore';
import { mapActions, mapState } from 'pinia';
import {
  checkIfTokenIsValid,
  getAuthToken,
  isInvalidToken,
  removeTokenIfExpired, setAuthToken
} from "@/utils/token";

let timeoutIdx

export default {
  components: {
    Header,
    Loader,
    GuestHeader,
    Footer,
    JoinModal
  },
  data: () => ({
    isLoaded: false,
    bidderNumbers: null,
    bannerUrl: localFeatures.urlBannerAuctionhouse,
    lang: navigator.language.split('-')[0],
  }),
  computed: {
    SocketDisconnectReason() {
      return SocketDisconnectReason
    },
    ...mapState(useAuctionStore, ['currentAuction', 'joinModal']),
    ...mapState(useRootStore, ['isAuthenticatedAsUser', 'loading', 'userProfile', 'error', 'errorMessage', 'appLoading', 'appLocalization', 'appFeatures', 'categories', 'alert', 'auctionsFetchedArticles', 'isOffline', 'adminDrawer', 'isLogoutClicked', 'showAuctionOverview', 'socketDisconnectReason', 'socket', 'socketConnectionStatus']),
  },
  watch: {
    userProfile() { // triggered by login
      if ((this.userProfile?.role === Role.user || this.userProfile?.role === Role.auctioneer || this.userProfile?.role === Role.viewer) && this.socketConnectionStatus === SocketConnectionStatus.init) {
        this.setUpSocket()
      }
      if (this.userProfile?.role === Role.admin && this.socketConnectionStatus === SocketConnectionStatus.init && this.$route.name !== 'auctionAdmin' && this.$route.name !== 'login') {
        this.setUpAdminSocket()
      }
      // TODO removing this leads to a broken view (spinner instead of login page) on failed login/auth
      // TODO removing the _.isEmpty() call leads to the auction beeing stopped in the admin (but not in the backend) after a jump to another article. Bidding for the users is impossible
      if (this.userProfile && _.isEmpty(this.appLocalization)) {
        this.getUserData()
      }
      const rootStore = useRootStore()
      rootStore.setBrowserTabId();
    },
    /* async '$route.name'(val) {
      if (val !== 'dashboard' && val !== 'auctionAdmin' && val !== 'login' && this.socket && this.socketConnectionStatus === SocketConnectionStatus.connected) {
        console.log('changed socket listeners on route change')
        if (this.userProfile.role === 'user') {
          await this.socket.removeAllListeners()
          this.setUpSocket()
        }
      }
      // don't set up the socket for the auctioneers and viewer screen as it has its own event handlers
      else if (this.isAuthenticatedAsUser && this.socket && this.socketConnectionStatus === SocketConnectionStatus.init && val !== 'auctioneer' && val !== 'auctionViewer') {
        this.setUpSocket()
        this.socket.connect()
      }
    } */
    // if the backend forcefully closes the socket connection because of a logout (jwt expired) or kickout
    async isOffline(newVal, oldVal) {
      const rootStore = useRootStore()
      if (!oldVal && newVal) {
        console.log('offline status detected, resason is: ' + this.socketDisconnectReason)
        if (!this.isAuthenticatedAsUser) return
        if (this.socketDisconnectReason === SocketDisconnectReason.ioServerDisonnect && !_.isEmpty(this.userProfile)) {
          if (checkIfTokenIsValid(getAuthToken())) {
            console.log('kickout detected')
            await this.kickOut()
            rootStore.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('An unexpected error occured and you have been logged out. Please login again.\nIf this persists, contact the auction house for more information') })
          } else {
            console.log('logout detected')
            this.logout()
            rootStore.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Your session has expired. Please login again') })
          }
        }
      } else {
        console.log('online status detected')
      }
      //this.$forceUpdate()
    }
},
  created() {
    window.addEventListener('resize', this.resizeHandler)
    console.log('breakpoint used: ' + this.$vuetify.breakpoint.name)
    this.$root.$on('showCookieBanner', () => this.$refs.cookies.init());
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  async mounted() {
    this.isLoaded = false
    if (this.$route.query.token && checkIfTokenIsValid(this.$route.query.token)) {
      setAuthToken(this.$route.query.token)
    }
    await this.getUserData()
  },
  methods: {
    ...mapActions(useRootStore, ['APP_GLOBAL_STORE_LANG', 'UPDATE_GLOBAL_STATE', 'SET_IS_MOBILE', 'getAppSettings', 'getUserSettings', 'getCurrentUserProfile', 'kickOut', 'logout', 'SET_TEMP_ALERT', 'CLEAR_ALERT', 'SET_ALERT', 'UPDATE_AUCTIONS_FETCHED_ARTICLE', 'REMOVE_AUCTIONS_FETCHED_ARTICLE', 'ADD_AUCTIONS_FETCHED_ARTICLE', 'SET_CATEGORIES', 'ADD_CATEGORY', 'REMOVE_CATEGORY', 'SET_SOCKET_DATA']),
    ...mapActions(useAuctionStore, ['CHANGE_STATE', 'SET_USER_DATA', 'getCurrentActiveAuction']),
    closeErrorModal() {
      this.UPDATE_GLOBAL_STATE({ key: 'error', value: false })
    },
    resizeHandler() {
      this.SET_IS_MOBILE()
    },
    async showJoinModal() {
      if (!this.bidderNumbers) {
        this.bidderNumbers = await getBidderNumbersFromUser(this.userProfile.id)
      }
      const target = this.bidderNumbers.filter(el => el.auction_id === this.currentAuction.id)
      const fitRequirementUser = target.find(el => el.user_status === 'unlocked' && el.type === 'live')
      const fitRequirementAuction = (this.currentAuction.status === AuctionStatusType.started || AuctionStatusType.paused)
      const fitRequirementNotOnAuctionView = this.$route.name !== 'dashboard' || (this.$route.name === 'dashboard' && this.showAuctionOverview)
      this.CHANGE_STATE({ key: 'joinModal', value: fitRequirementUser && fitRequirementAuction && fitRequirementNotOnAuctionView })
    },
    setUpSocket () {
      const { socket } = SocketClient.getInstance();
      const emitter = SocketHandlers.getEmitter();
      emitter.on(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, async data => {
        await this.showJoinModal()
      });

      emitter.on(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, async data => {
        await this.showJoinModal()
      });

      this.SET_SOCKET_DATA(socket)
      this.socket.connect()
    },
    setUpAdminSocket () {
      const { socket } = SocketClient.getInstance('admins');
      this.SET_SOCKET_DATA(socket)
      this.socket.connect()
    },
    cookieClickedAccept() {
      this.UPDATE_GLOBAL_STATE({ key: 'cookiesStatus', value: true });
    },
    cookieClickedDecline() {
      this.UPDATE_GLOBAL_STATE({ key: 'cookiesStatus', value: false })
      window.localStorage.removeItem('vue-cookie-accept-decline-cookies')
    },
    /**
     * get the user profile. If there is a previous valid jwt (not expired) use this.
     * If there is no token, do nothing
     * If there is no session for this user, reset the app
     * @return {boolean} - True if no errors occured, false if directory doesn't exist
     */
    async getUserData() {
      try {
        this.isLoaded = false
        this.UPDATE_GLOBAL_STATE({
          key: 'appLoading',
          value: false,
        })
        if (getAuthToken()) {
          const token = getAuthToken()
          console.log('jwt exists')
          removeTokenIfExpired(token);
          if (!checkIfTokenIsValid(token)) {
            // jwt is expired
            console.log('deleting previous session data from local storage')
            console.log('deleting previous cookies from previous session')
            const cookies = localStorage.getItem('vue-cookie-accept-decline-cookies')
            localStorage.clear()
            if (cookies != null) {
              localStorage.setItem('vue-cookie-accept-decline-cookies', cookies)
            }
            Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
            if (router.currentRoute.name !== 'login') router.push({ name: 'login' })
            await this.getAppSettings()
            // specifying the locale manually is needed since the autodetection of the ui language (vuex) is not ready here
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Your session has expired. Please login again', this.lang) })
          }
        } else {
          console.log('jwt doesnt exist')
        }
        if (!isInvalidToken(getAuthToken()) && checkIfTokenIsValid(getAuthToken())) {
          // Load all default settings for the application
          if (await this.getUserSettings() === null) { // sometimes the backend drops the session, log out in this case
            console.log('trying to login but there is no backend session. Resetting the application')
            await this.kickOut()
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('An unexpected error occured and you have been logged out. Please login again.\nIf this persists, contact the auction house for more information') })
            return
          }

          const data = await this.getCurrentUserProfile()
          this.SET_USER_DATA(data)
          if (localFeatures.useSentry) {
            Sentry.setUser({ email: data.email });
          }
          let foundLang = this.appLocalization.i18n.availableLanguages.find((it) => it.lang === this.userProfile.language)
          i18n.locale = foundLang.lang
          this.APP_GLOBAL_STORE_LANG(foundLang.lang)
        } else if (!getAuthToken()) {
          await this.getAppSettings()
        }
        this.UPDATE_GLOBAL_STATE({
          key: 'loading',
          value: false,
        })
        this.UPDATE_GLOBAL_STATE({
          key: 'appLoading',
          value: true,
        })
        this.isLoaded = true
      } catch (e) {
        this.UPDATE_GLOBAL_STATE({
          key: 'appLoading',
          value: true,
        })
        this.UPDATE_GLOBAL_STATE([
          {
            key: 'error',
            value: true,
          },
          {
            key: 'errorMessage',
            value: e.message,
          },
        ])
        this.isLoaded = true
      }
    }
  }
}

</script>
