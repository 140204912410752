<template>
  <v-dialog
    class="lighten-5 guest-forms"
    id="container"
    v-model="resetPasswordModal"
    @keydown.esc="signInAccount"
    max-width="600"
    @keydown.enter="isFormValid ? submitResetPassword() : {};"
    persistent
  >
    <v-card class="px-8 py-4">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="signInAccount"><v-icon>fa-times</v-icon></v-btn>
      <div align="center" class="form-container ma-0">
        <v-row>
          <v-col align="center">
            <h2 class="form-title">{{ $t('Change password') }}</h2>
            <div class="form-title mt-9 text-left pl-3">{{ $t('Please enter a new password') }}</div>
            <v-form class="form-wrapper pa-0 mt-5" ref="passwordResetForm" v-model="isFormValid">
              <v-col align="center">
                <div class="input-field password-strength-block">
                  <v-text-field
                    v-model="newPassword"
                    :label="$t('Password')"
                    :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    class="input-field input-field-small-append-icon customizable"
                    :rules="[validationRequired(), validationPassword()]"
                    ref="passwordField"
                    :class="{'error-border': !newPassword && showMessage}"
                    single-line >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>
                  <!-- <v-progress-linear  class="strength-bar" :color="score.color" :value="score.value"></v-progress-linear> -->
                  <v-text-field
                    v-model="confirmationNewPassword"
                    :label="$t('Confirm Password')"
                    type="password"
                    :rules="[validationRequired(), validationPasswordConfirmation(newPassword, confirmationNewPassword)]"
                    class="input-field input-field-small-append-icon customizable"
                    :class="{'error-border': !confirmationNewPassword && showMessage}"
                    single-line
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-form>
            <v-col align="center" class="no-padding-bottom">
              <v-btn
                :disabled="!isFormValid"
                height="50"
                class="btn primary-btn reset-password-btn"
                @click="submitResetPassword">
                  {{ $t('Change password') }}
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { timeouts } from '@/utils/constants';
import apiRequests from '@/api/apiRequests';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';

export default {
  data: () => ({
    resetPasswordModal: false,
    showPassword: false,
    /* email: null, */
    newPassword: null,
    confirmationNewPassword: null,
    showMessage: false,
    message: '',
    toolTipPosition: 'right',
    pageLang: 'de',
    isFormValid: false
  }),
  computed: {
    ...mapState(useRootStore, ['appSettings', 'appStoreLang']),
    /* score() {
      if(this.newPassword)
        return passwordStrength(this.newPassword)
      else
        return {color:'red',score:0}
    }, */
  },
  methods: {
    validationPassword: frontendValidations.password,
    validationRequired: frontendValidations.required,
    validationPasswordConfirmation: frontendValidations.passwordConfirmation,
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'APP_GLOBAL_STORE_LANG']),
    signInAccount () {
      this.$refs.passwordResetForm.reset()
      this.$router.push({ name: 'login' })
      this.closeModal()
    },
    activeButton() {
      return (!this.newPassword || !this.confirmationNewPassword || this.newPassword !== this.confirmationNewPassword || typeof this.rules.password(this.newPassword) === 'string')
    },
    async submitResetPassword() {
      let resetData = {
        password: this.newPassword,
        code: this.$route.query.code
      }

      // Show loading
      this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });

      // Call sign up account
      try {
        await apiRequests.confirmResetPassword(resetData);

        this.$swal.fire({
          position: 'center',
          icon: 'success',
          title: this.$t('Done'),
          text: this.$t('Your password has been changed successfully'),
          showConfirmButton: false,
          timer: timeouts.closeSweetalert
        })
        this.signInAccount()
      } catch (e) {
        if (e.data?.error && e.data.errors[0].errorShortText === 'confirmation.code.invalid') {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('The code you tried to use for resetting the password is either expired or was used already'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
        } else {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('There was an error changing the password. Please try again later'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
        }
      } finally {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
        this.closeModal()
      }
    },
    showTooltip(message, errorField = 'email') {
      // Get width
      if (window.innerWidth < 968) {
        this.toolTipPosition = 'bottom';
      } else {
        this.toolTipPosition = 'right';
      }

      this.message = message;
      this.showMessage = true;
      this.errorField = errorField;

      // Hide after 5s
      setTimeout(() => {
        this.showMessage = false;
        this.errorField = null;
      }, 5000);
    },
    openDialog(section, dialogType = 'confirm', ...args) {
      this.dialogFor = section;
      this.$refs.dialog.open(dialogType, ...args);
    },
    handleOk() {
      this.$refs.dialog.close()
      this.$router.push('/login')
    },
    isShowErrorTooltip(fieldName) {
      if (!this.showMessage || !this.errorField) {
        return false;
      }
      return fieldName === this.errorField;
    },
    openModal() {
      this.resetPasswordModal = true
      this.pageLang = this.$route.query.lang
      this.APP_GLOBAL_STORE_LANG(this.pageLang)
      if (this.$refs.passwordField) this.$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1
    },
    closeModal() {
      this.resetPasswordModal = false
    }
  }
}
</script>

<style>
</style>
