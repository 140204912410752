<script>

import useRootStore from '@/stores/rootStore';
import { mapActions } from 'pinia';
import queryString from 'query-string'

export default {
  name: 'ArticleDetails',
  data() {
    return {
      selectedDetailedArticle: null,
      articles: [],
      articlesViewUnsliced: [],
      previousUrl: null,
      updatePreviousUrl: true
    }
  },
  computed: {
    /**
     * Return the index of the previous article compared to the current one (selectedDetailedArticle)
     * @return {any} - The previous article
     */
    prevArticle() {
      if (!this.selectedDetailedArticle) return null
      const idx = this.articlesViewUnsliced.findIndex(el => el.id === this.selectedDetailedArticle.id)
      if (!idx) return null
      return this.articlesViewUnsliced[idx - 1]
    },
    /**
     * Return the index of the next article compared to the current one (selectedDetailedArticle)
     * @return {any} - The next article
     */
    nextArticle() {
      if (!this.selectedDetailedArticle) return null
      const idx = this.articlesViewUnsliced.findIndex(el => el.id === this.selectedDetailedArticle.id)
      if (idx === this.articlesViewUnsliced.length - 1) return null
      return this.articlesViewUnsliced[idx + 1]
    }
  },
  watch: {
    prevArticle() {
      if (this.previousUrl) this.updatePreviousUrl = false
    },
    nextArticle() {
      if (this.previousUrl) this.updatePreviousUrl = false
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    /**
     * Display the article details modal for an article
     * @param {any} article - The article to display
     * @return {void} - Nothing
     */
    openArticleDetailedModal(article) {
      this.selectedDetailedArticle = article
      // only push if the modal is opened via catalogue, post-auction-sale etc. (and not via a direct url)
      if ((this.$route.name === 'catalogue' || this.$route.name === 'postAuctionSale' || this.$route.name === 'pastAuctions') && (!this.$route.params.article)) {
        // console.log(this.$route.fullPath)
        if (this.updatePreviousUrl) this.previousUrl = this.$route.fullPath
      }
      if (this.$route.name !== 'dashboard') {
        this.$router.push({
          name: this.$route.name,
          params: {
            id: this.$route.params.id,
            article: 'article',
            articleNumber: (article.number_optional) ? article.number_optional : article.number,
          }
        })
      }
      this.$refs.articleDetailsModal.openModal()
    },
    closeArticleDetailsModal() {
      if (this.previousUrl !== null) {
        let pageFromPreviousUrl;
        // console.log('url ' + this.previousUrl)
        const urlParts = this.previousUrl.split('?')
        if (urlParts.length > 1) {
          const queryParams = queryString.parse(urlParts[1])
          if (queryParams.page) pageFromPreviousUrl = queryParams.page
          // console.log('previous page from url ' + pageFromPreviousUrl)
          // console.log('current page ' + (this.page +1))
          if (pageFromPreviousUrl !== (this.page + 1)) this.previousUrl = this.previousUrl.replace('page=' + pageFromPreviousUrl, 'page=' + (this.page + 1))
        }
        this.$router.push(this.previousUrl)
        this.previousUrl = null
        this.updatePreviousUrl = true
      }
    },
    /**
     * Check the external url. If the article provided doesn't exist, display an error
     * @return {void} - Nothing
     */
    checkUrl() {
      if (this.$route.query && (this.$route.query.articleNumber || this.$route.params.articleNumber)) {
        let articleNumber = this.$route.query.articleNumber ? this.$route.query.articleNumber : this.$route.params.articleNumber
        const article = this.articles.find(el => ((el.number_optional) ? parseInt(el.number_optional) : +el.number) === +articleNumber)
        let error = false;
        if (!article) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t("Article doesn't exist") })
          error = true
        } else if (!this.selectedAuctionData && this.selectedAuctionData.type === 'timed' && article && article.status === '2') {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Invalid request received') })
          error = true
        }
        if (error) {
          this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
          return
        }
        if (this.$route.query.action === 'timedBid') {
          this.openTimedBidModal(article)
        } else {
          this.openArticleDetailedModal(article)
        }
      }
    }
  }
}
</script>
