<template>
  <div class="auction-container settings logging">
    <v-container class="table-template-wrap">
      <v-row class="table-container">
        <v-col align="center">
          <v-data-table
            :headers="headers"
            :items="logs"
            :items-per-page="itemPerPage"
            class="elevation-1 settings"
            hide-default-footer
          >
            <template #[`item.id`]="{ item }">
              <span class="text-light">{{ item.id }}</span>
            </template>

            <template #[`item.created_at`]="{ item }">
              <span class="text-light">{{ new Date(item.created_at).toUTCString() }}</span>
            </template>

            <template #[`item.entry`]="{ item }">
              <span class="text-light">{{ stringify(JSON.parse(item.entry), null, 2) }}</span>
            </template>

            <template #top>
              <v-row no-gutters>
                <v-col
                  sm="6"
                >
                  <div class="table-top">
                    <div class="table-title">{{ $t('Activity Logs') }}</div>
                  </div>
                </v-col>
                <v-col
                  class="table-top flex-row-reverse"
                  sm="6"
                >
                  <v-menu offset-y close-on-content-click>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        text small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ $t('Interval time') }}</span><span class="highlight ml-1">{{ intervalTime }}s<v-icon>fa-angle-down</v-icon></span>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in [1, 2, 5, 10]"
                        :key="index"
                        @click="onChangeInterval(item)"
                      >
                        <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y close-on-content-click>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        text small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ $t('Entries per page') }}</span><span class="highlight ml-1">{{ itemPerPage }}<v-icon>fa-angle-down</v-icon></span>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in [10, 20, 30]"
                        :key="index"
                        @click="onChangeItemPerPage(item)"
                      >
                        <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <div class="table-footer v-toolbar__content">
            <div class="v-responsive">
              <v-btn class="btn primary-btn al-btn" :disabled="currentPage === 0" @click="onChangePage(currentPage - 1)">{{ $t('Previous') }}</v-btn>
            </div>
            <v-spacer />
            <div>
              <v-btn
                class="btn primary-btn al-btn" :disabled="((currentPage + 1) * itemPerPage) >= totalLogs"
                @click="onChangePage(currentPage + 1)"
              >
                {{ $t('Next') }}
              </v-btn>
            </div>

            <div class="pages">{{ currentPage + 1 }} of {{ totalPages }}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiRequests from '@/api/apiRequests'

export default {
  name: 'LogsView',
  computed: {
    totalPages () {
      const div = this.totalLogs / this.itemPerPage
      const flooredDiv = Math.floor(div)
      return flooredDiv + (div !== flooredDiv ? 1 : 0)
    },
  },
  data: function () {
    return {
      loading: true,
      itemPerPage: 10,
      currentPage: 0,
      totalLogs: 0,
      intervalTime: 10,
      headers: [
        {
          text: this.$t('ID'),
          value: 'id',
          sortable: false,
        },
        {
          text: this.$t('Timestamp'),
          value: 'created_at',
          sortable: false,
        },
        // {
        //   text: this.$t('Category'),
        //   value: 'category',
        //   sortable: false,
        // },
        {
          text: this.$t('Log Level'),
          value: 'level',
          sortable: false,
        },
        // {
        //   text: this.$t('Activity'),
        //   value: 'activity',
        //   sortable: false,
        // },
        {
          text: this.$t('Client IP'),
          value: 'ip',
          sortable: false,
        },
        {
          text: this.$t('Client UUID'),
          value: 'client_uuid',
          sortable: false,
        },
        {
          text: this.$t('Username'),
          value: 'username',
          sortable: false,
        },
        {
          text: this.$t('HTTP return code'),
          value: 'return_code',
          sortable: false,
        },
        {
          text: this.$t('Log entry'),
          value: 'entry',
          sortable: false,
        },
      ],
      logs: [],
      interval: null,
    }
  },
  methods: {
    stringify: JSON.stringify,
    exportReport () {
      console.log('export report')
    },
    async getLogs() {
      try {
        this.loading = true;
        const { data } = await apiRequests.getLogs(this.itemPerPage, this.currentPage * this.itemPerPage)
        const { logs, count } = data.data
        this.logs = logs;
        this.totalLogs = count;
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.loading = false;
      }
    },

    onChangePage(pageNumber) {
      this.currentPage = pageNumber;
      this.getLogs();
    },

    onChangeItemPerPage(item) {
      this.itemPerPage = item;
      this.currentPage = 0;
      this.getLogs();
    },

    onChangeInterval(interval) {
      this.intervalTime = interval;
      this.setUpInterval();
    },

    setUpInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.getLogs();
      }, this.intervalTime * 1000)
    }
  },
  async created () {
    await this.getLogs();

    this.setUpInterval();
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
