<template>
  <v-row class="article-overview">
    <v-col align="center">
      <h3 class="section-title mb-6">{{ $t('Articles overview') }}</h3>
      <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
      <v-card class="main-card pr-6 pt-6" flat v-else-if="!error">
        <v-data-table
          :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
          :items="disabled ? tableData : articlesView"
          :items-per-page="itemPerPage"
          class="settings remove-border-last-col"
          @click:row="handleArticleRowClick"
          :class="{disabled}"
          item-class="edit-article-row"
          v-model="selectedArticles"
          show-select
          ref="table"
          dense
          must-sort
          :no-data-text="$t('No data available')"
          :footer-props="{
            'items-per-page-all-text': $t('All'),
            'items-per-page-text': $t('Rows per page'),
            'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
            'items-per-page-options': [10, 25, 50, 100]
          }"
        >
          <template #[`item.number`]="{ item }">
            <span v-if="item.number_optional">{{ `${item.number_optional}(${ item.number})` }}</span>
            <span v-if="!item.number_optional">{{ _.padStart(item.number, 4, '0') }} </span>
          </template>
          <template #[`item.status`]="{ item }">
            <span>{{ $t(statusNames[item.status].text) }}</span>
          </template>
          <template #[`item.name`]="{ item }">
            <div class="article-name-col">
              <div class="article-image">
                <v-img v-if="item.images && item.images.length && item.images[0].image" :src="item.images[0].image" contain width="50px" height="50px" />
                <v-img v-else-if="item.image" :src="item.image" contain width="50px" height="50px" />
              </div>
              <div class="article-name">{{ item.name }}</div>
            </div>
          </template>
          <template #[`item.dates`]="{ item }">
            <span v-if="item.timedData">{{ getFormattedDate(item.timedData.active_from, appStoreLang, false, false) }}</span>
            <br>
            <span v-if="item.timedData">{{ getFormattedDate(item.timedData.active_until, appStoreLang, false, false) }}</span>
          </template>
          <template #[`item.limit`]="{ item }">
            <span>{{ currency }}{{ euNumbers(item.limit) }}</span>
          </template>
          <template #[`item.knockdown`]="{ item }">
            <span v-if="item.knockdown">{{ currency }}{{ euNumbers(item.knockdown) }}</span>
          </template>
          <template #[`item.hammer_price`]="{ item }">
            <span v-if="item.timedData && item.timedData.current_hammer_price">{{ currency }}{{ euNumbers(item.timedData.current_hammer_price) }}</span>
          </template>
          <template #[`item.bidder_num`]="{ item }">
            <span v-if="item.timedData">{{ item.timedData.bidder_number }}</span>
            <span v-else>{{ item.bidder_num }}</span>
          </template>
          <template #[`item.type`]="{ item }">
            <span>{{ item.type ? $t('Live') : $t('Room') }}</span>
          </template>
          <template #[`item.action`]="{ item }">
            <v-icon small @click.stop="startArticle(item.id)" class="icon start-icon" v-if="item.status === '1' && currentAuction.type === 'timed'">
              fa-play
            </v-icon>
            <v-icon small @click.stop="stopArticle(item.id)" class="icon stop-icon" v-else-if="item.status === '2' && currentAuction.type === 'timed'">
              fa-stop
            </v-icon>
            <v-icon small @click.stop="$emit('openUpsertArticleModal', item)" class="icon icon-with-hover">
              fa-edit
            </v-icon>
            <v-icon small @click.stop="$emit('deleteArticle', item)" class="icon icon-with-hover">
              fa-trash-can
            </v-icon>
          </template>

          <template #top>
            <v-row no-gutters>
              <v-col
                class="table-top flex-row-reverse justify-space-around mb-6"
                sm="6"
                offset-sm="3"
                v-if="!disabled"
              >
                <v-btn class="btn" @click="exportArticles"><span class="text-bold">{{ $t('Export articles') }}</span></v-btn>
                <al-input-file-btn
                  :btn-text="$t('Import articles')"
                  @fileChange="handleImportedFile"
                  acceptFile=".csv"
                  text-bold
                />
              </v-col>
            </v-row>
            <div class="add-item-btn-container mb-6" v-if="!disabled">
              <v-btn @click="$emit('openUpsertArticleModal')">
                <v-icon class="mr-2">fa-circle-plus</v-icon>
                {{ $t('Add Article') }}
              </v-btn>
            </div>
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="[{ text: 'All', value: 'all' }, ...statusValues]"
                  :label="$t('Status')"
                  placeholder=" "
                  v-model="statusFilter"
                  class="select-input"
                  append-icon="fa-angle-down"
                  :item-text="_item => $t(_item.text)"
                  @input="searchHandler"
                />
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  append-icon="fa-magnifying-glass"
                  :label="$t('Search')"
                  hide-details
                  class="mb-3"
                  @input="searchHandler"
                  clearable
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <div class="delete-item-btn-container row pb-8">
          <v-btn width="auto" class="btn error-btn" @click="deleteArticlesBulk" :disabled="selectedArticles.length < 2">
            {{ $t('Delete selected articles') }} ( {{ selectedArticles.length }} )
          </v-btn>
        </div>
        <dialog-confirm
          ref="dialog"
          @onOk="handleOk()"
        />
        <stop-article-modal ref="stopArticleModal" @submit="submitStopArticle" @close="activeArticleId = null" />
        <start-article-modal ref="startArticleModal" @submit="submitStartArticle" @close="activeArticleId = null" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { processCSVFile } from '@/utils/file'
import _ from 'lodash'
import DialogConfirm from '@/components/dialog/dialog-confirm'
import AlInputFileBtn from '@/components/form/input-file'
import StopArticleModal from '@/components/modals/admin-timed-article-end-modal'
import StartArticleModal from '@/components/modals/admin-timed-article-start-modal'
import { mapActions, mapState } from 'pinia'
import apiRequests from '@/api/apiRequests'
import { parse } from 'json2csv'
import { statusNames } from '@/utils/constants'
import useRootStore from '@/stores/rootStore';
import { createArticleSearchIndex, searchArticles } from '@/services/article';
import { euNumbers, getFormattedDate } from '@/services/i18n';

export default {
  name: 'ArticleDashboard',
  components: {
    DialogConfirm,
    AlInputFileBtn,
    StopArticleModal,
    StartArticleModal
  },
  props: {
    disabled: Boolean,
    articles: Array,
    auctions: Array,
    auctionId: Number,
    loading: Boolean,
    error: Boolean
  },
  data: () => ({
    search: '',
    itemPerPage: 50,
    page: 0,
    start: 0,
    end: 0,
    statusNames,
    statusFilter: 'all',
    tableData: [
      {
        number: '----',
        // article_image: '../../@/assets/logo.svg',
        name: '----',
        limit: '----',
        estimated_price: '----'
      },
    ],
    dialog: false,
    selectedArticles: [],
    searchResult: [],
    mutableArticles: [],
    articlesView: [],
    currentAuction: null,
    activeArticleId: null,
    searchKeys: ['name', 'description'], // number and number_optional will always be searched
    articleSearchIndex: null,
  }),
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'appStoreLang']),
    currency() {
      return this.appLocalization.currency
    },
    statusValues() {
      const names = []
      for (const key of Object.keys(this.statusNames)) {
        names.push(this.statusNames[key])
      }
      return names
    },
    headers() {
      if (this.currentAuction && this.currentAuction.type === 'timed') {
        return [
          {
            text: this.$t('Article #'),
            value: 'number',
            sortable: true,
            width: 110
          },
          {
            text: this.$t('Status'),
            value: 'status',
            align: 'left',
            width: 110
          },
          {
            text: this.$t('Name'),
            value: 'name',
            align: 'left',
            sortable: true,
            width: 300
          },
          {
            text: this.$t('Duration from - to'),
            value: 'dates',
            align: 'left',
            sortable: false,
            width: 130
          },
          {
            text: this.$t('Limit'),
            value: 'limit',
            align: 'left',
            sortable: true,
            filterable: false,
            width: 55
          },
          {
            text: this.$t('Estimated price'),
            value: 'estimated_price',
            align: 'left',
            sortable: true,
            filterable: false,
            width: 75
          },
          {
            text: this.$t('Hammer price'),
            value: 'hammer_price',
            align: 'left',
            sortable: true,
            filterable: false,
            width: 75
          },
          {
            text: this.$t('Knockdown'),
            value: 'knockdown',
            align: 'left',
            sortable: true,
            filterable: false,
            width: 75
          },
          {
            text: this.$t('Paddle'),
            value: 'bidder_number',
            align: 'left',
            sortable: false,
            filterable: false,
            width: 65
          },
          {
            text: this.$t('Type'),
            value: 'type',
            align: 'right',
            sortable: true,
            filterable: false,
            width: 70
          },
          {
            value: 'action',
            align: 'center',
            width: 90,
            sortable: false,
          },
        ]
      } else {
        return [
          {
            text: this.$t('Article #'),
            value: 'number',
            sortable: true,
            width: 100
          },
          {
            text: this.$t('Status'),
            value: 'status',
            align: 'left',
            width: 110
          },
          {
            text: this.$t('Name'),
            value: 'name',
            align: 'left',
            sortable: true,
          },
          {
            text: this.$t('Limit'),
            value: 'limit',
            align: 'left',
            sortable: true,
            filterable: false,
            width: 55
          },
          {
            text: this.$t('Estimated price'),
            value: 'estimated_price',
            align: 'right',
            sortable: true,
            filterable: false,
            width: 75
          },
          {
            text: this.$t('Knockdown'),
            value: 'knockdown',
            align: 'right',
            sortable: true,
            filterable: false,
            width: 75
          },
          {
            text: this.$t('Paddle'),
            value: 'bidder_num',
            align: 'right',
            sortable: true,
            filterable: false,
            width: 65
          },
          {
            text: this.$t('Type'),
            value: 'type',
            align: 'right',
            sortable: true,
            filterable: false,
            width: 70
          },
          {
            value: 'action',
            align: 'center',
            width: 90,
            sortable: false,
          },
        ]
      }
    }
  },
  async created() {
    this.articleSearchIndex = createArticleSearchIndex(this.articles);
  },
  watch: {
    articles() {
      this.mutableArticles = [...this.articles]
      this.searchResult = [...this.articles]
      this.getArticlesData()
      this.articleSearchIndex = createArticleSearchIndex(this.articles);
    }
  },
  mounted() {
    this.mutableArticles = [...this.articles]
    this.searchResult = [...this.articles]
    this.getArticlesData()
    this.currentAuction = this.auctions.find(el => el.id === this.auctionId)
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    searchArticles,
    euNumbers,
    getFormattedDate,
    searchHandler: _.debounce(function () {
      if (this.search) {
        let articles = [...this.mutableArticles].filter(el => this.statusFilter === 'all' ? true : el.status === this.statusValues.findIndex(item => item.value === this.statusFilter).toString())
        this.searchResult = this.searchArticles(this.articleSearchIndex, this.search, articles, this.searchKeys, this.appStoreLang)
      } else {
        this.searchResult = this.mutableArticles.filter(el => this.statusFilter === 'all' ? true : el.status === this.statusValues.findIndex(item => item.value === this.statusFilter).toString())
      }
      this.getArticlesData()
    }, 500),
    getArticlesData() {
      this.articlesView = this.searchResult
    },
    changePage(el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
      this.getArticlesData()
    },
    getArticleNumber(itm) {
      if (itm.number_optional && itm.number_optional.length > 0) {
        return `${itm.number_optional}(${itm.article})`
      } else {
        return itm.article
      }
    },
    handleEdit (item) {
      console.log(item)
    },

    deleteArticlesBulk() {
      this.openDialog(
        true,
        'deleteArticles',
        'confirm',
        this.$t('Delete Article'),
        this.$t('Are you sure to delete this article?')
      );
    },

    async deleteArticles() {
      if (this.selectedArticles.length < 2) {
        return;
      }

      this.$refs.dialog.close()

      try {
        const allArticles = this.selectedArticles.map(article => apiRequests.removeArticle(article.auction_id, article.id)); // TODO cache in backend is not synchronized

        await Promise.all(allArticles)

        const updatedArticlesArray = this.mutableArticles.filter(ar => !this.selectedArticles.find(rm => (rm.id === ar.id)))
        this.mutableArticles = [...updatedArticlesArray]

        this.searchResult = [...this.mutableArticles]
        this.getArticlesData()

        this.selectedArticles = []
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article deleted successfully') })

        // this.forceRerender()
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: `${this.$t('There was an error deleting the article')}\n${this.$t(e.data.errors[0].errorShortText)}` })
        }
      }
    },

    handleArticleRowClick(value) {
      this.$emit('openUpsertArticleModal', value)
    },
    async handleImportedFile(file) {
      const { data } = await processCSVFile(file);
      // let error = false
      // data.map(item => {
      //   if (!item.estimated_price || !item.number_optional) error = true
      // })
      // if (error) {
      //   return false
      // }
      const newArticles = data.map(item => {
        const estimatedPrice = item.estimated_price.replace(/[\s\t\r]/g, '')
        const numberOptional = item.number_optional.replace(/[\s\t\r]/g, '')
        return {
          number: parseInt(item.number.replace(/[\s\t\r]/g, '')),
          number_optional: (numberOptional === 'null' || numberOptional == null) ? '' : numberOptional,
          name: item.name.replace(/[\t\r]/g, ''),
          description: item.description, // .replace(/[\s\t\r]/g, ''),
          limit: parseFloat(item.limit.replace(/[\s\t\r]/g, '')),
          estimated_price: estimatedPrice.length > 0 ? estimatedPrice : null,
        }
      }).filter(item => (item.estimated_price && item.estimated_price.length > 0) ? item.estimated_price.match(/\d+-\d+/) : true);
      this.$emit('onImportArticles',
                 newArticles,
                 'confirm',
                 this.$t('Import articles'),
                 this.$t('Importing will delete all existing articles for this auction. Are you sure?')
      );
    },

    openDialog(isCancel = true, section, dialogType = 'confirm', ...args) {
      this.dialogFor = section
      this.cancelLabel = this.$t('Cancel')
      if (isCancel === false) { this.cancelLabel = this.$t('Ok') }

      this.$refs.dialog.open(dialogType, ...args);
    },

    handleOk() {
      const actions = {
        deleteArticles: this.deleteArticles,
      }
      if (actions[this.dialogFor]) actions[this.dialogFor]()
    },
    async exportArticles () {
      // let knockdowns = await this.getKnockdowns(this.currentAuction.id)
      // console.log(this.mutableArticles)
      // let a = document.createElement('a')
      // a.href = `data:application/octet-stream,${encodeURIComponent(this.mutableArticles)}`
      // a.download = 'articles_export_' + this.auctionId + '.csv'
      // a.click()
      const fields = ['number', 'number_optional', 'name', 'name_i18n', 'description', 'description_i18n', 'limit', 'estimated_price', 'offensive', 'no_shipping'];
      const opts = { fields };

      try {
        const csv = parse(this.mutableArticles, opts);
        let a = document.createElement('a')
        a.href = `data:application/octet-stream,${encodeURIComponent(csv)}`
        a.download = 'articles_export_' + this.auctionId + '.csv'
        a.click()
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('All articles exported successfully') })
      } catch (err) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Article export failed') + '' + err })
        console.error(err);
      }
    },
    startArticle(id) {
      this.$refs.startArticleModal.openModal()
      this.activeArticleId = id
    },
    stopArticle(id) {
      this.$refs.stopArticleModal.openModal()
      this.activeArticleId = id
    },
    async submitStopArticle(force) {
      try {
        const payload = {
          auctionId: this.auctionId,
          articleId: this.activeArticleId,
          force
        }
        await apiRequests.stopArticle(payload)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('The article has been stopped successfully') })
        this.$refs.stopArticleModal.closeModal()
        this.$emit('reFetchArticles')
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error stopping the article') })
      }
    },
    async submitStartArticle() {
      try {
        const payload = {
          auctionId: this.auctionId,
          articleId: this.activeArticleId
        }
        await apiRequests.startArticle(payload)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('The article has been started successfully') })
        this.$refs.startArticleModal.closeModal()
        this.$emit('reFetchArticles')
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error starting the article') })
      }
    },
    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
      this.getArticlesData()
    }
  }
}
</script>
<style>
  .article-overview .v-data-table__wrapper tbody tr {
    cursor: pointer;
  }
</style>
<style lang="scss" scoped>
.pagination-container {
  justify-content: flex-end;
  align-items: center;
}
</style>
