<template>
  <v-dialog
    v-model="generalInquiryModal"
    width="700"
    @keydown.esc="closeModal"
    persistent
  >
    <v-card class="pa-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <h2 class="pt-2 text-center">{{ $t('General inquiry') }}</h2>
      <p class="pt-2 mb-4"><span>{{ $t('Type your general question here') }}</span></p>
      <v-form ref="generalInquiryForm" v-model="isFormValid">
        <v-textarea
          v-model="question"
          :label="$t('Your question')"
          required
          class="input-field"
          rows="6"
          :counter="maxInputLength"
          :placeholder="$t('Your question')"
          :rules="[validationRequired(), validationLessThanMaximum(this.maxInputLength)]"
          single-line
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-textarea>
        <div class="text-center mt-2">
          <v-btn
            class="btn primary-btn success full-width"
            :disabled="!isFormValid || disableButton"
            @click="submitGeneralInquiryRequest"
            :loading="loading"
          >
            <v-icon class="mr-2">fa-envelope</v-icon>
            {{ $t('Send message') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import dispatcher from '@/api/dispatch';
import { timeouts } from '@/utils/constants';
import {mapActions, mapState} from 'pinia';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';

export default {
  name: 'UserGeneralInquiryModal',
  data() {
    return {
      disableButton: false,
      loading: false,
      question: '',
      generalInquiryModal: false,
      maxInputLength: 500,
      isFormValid: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appStoreLang'])
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    validationRequired: frontendValidations.required,
    validationLessThanMaximum: frontendValidations.lessThanMaximum,
    async submitGeneralInquiryRequest() {
      try {
        const data = {
          type: 'general',
          message: this.question,
          lang: this.appStoreLang,
        }
        this.loading = true
        await dispatcher.sendInquiry(this.userProfile.id, data)
        this.loading = false
        this.disableButton = true
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Your inquiry has been sent. We will get back to you as soon as possible') })
        setTimeout(() => {
          this.closeModal()
        }, timeouts.closeModal)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error sending the inquiry. Please try again later') })
        this.disableButton = false
        this.loading = false
      }
    },
    openModal() {
      this.disableButton = false
      this.generalInquiryModal = true
    },
    closeModal() {
      this.generalInquiryModal = false
      this.$refs.generalInquiryForm.reset()
      this.isFormValid = false
    }
  }
}
</script>

<style scoped>

</style>
