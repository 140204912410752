<template>
  <div class="auction-container settings system" v-if="currentConfig">
    <div class="close-btn-wrapper">
      <v-btn class="close-btn" @click="$router.go(-1)">
        <v-icon class="close-modal-icon">fa-times</v-icon>
      </v-btn>
      <v-btn class="send-btn" @click="saveConfigs" :disabled="!dataIsChanged || !saveEnabled || loading" :loading="loading">
        <v-icon class="save-modal-icon mr-1">fa-save</v-icon>
        <span>{{ $t('Save') }}</span>
      </v-btn>
    </div>

    <form-template
      :formName="$t('General')"
      :formData="generalForm"
      @changeData="dataIsChanged = true"
      v-model="currentConfig.general"
    />

    <form-template
      :formName="$t('Streaming settings')"
      :formData="streamForm"
      @changeData="dataIsChanged = true"
      v-model="currentConfig.general"
    />

    <!-- Localization form -->
    <form-template
      :formName="$t('Localization')"
      :formData="localizationForm"
      v-model="currentConfig.localization"
      @changeData="dataIsChanged = true"
    />

    <v-container class="mb-10">
      <v-row>
        <v-col class="py-0">
          <div class="emailTestWrap validateBtnWrap d-flex align-center">
            <v-btn class="btn primary-btn validate-media-btn" @click="validateMedia()" :disabled="loading">
              <span>{{ $t('Test connection') }}</span>
            </v-btn>
            <p class="warning-message my-0 ml-3">
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="loading"
              />
              <v-icon key="icon-succes" color="#4CAF50" v-if="!loading && mediaStatus === ServerStatus.validation">fa-circle-check</v-icon>
              <v-icon key="icon-error" color="#FF3232" v-if="!loading && mediaStatus === ServerStatus.error">fa-circle-xmark</v-icon>
            </p>
            <p class="warning-message text-no-wrap my-0 ml-3 msg-success" key="ServerStatus.validation" v-if="!loading && mediaStatus === ServerStatus.validation">{{ $t(statusMessage) }}</p>
            <p class="warning-message text-no-wrap my-0 ml-3 msg-error" key="ServerStatus.error" v-if="!loading && mediaStatus === ServerStatus.error">{{ $t(statusMessage) }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="form-template-wrap">
      <v-row class="form-container justify-center">
        <v-col align="center" cols="12">
          <div class="form-title mb-5">{{ $t('Auction settings') }}</div>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col align="center">
              <v-checkbox
                :hide-details="true"
                class="input-field"
                v-model="currentConfig.general.autostart_articles"
                @change="dataIsChanged = true"
              >
                <template #label>
                  <div class="text" style="color: #181818;">
                    <span>{{ $t('Autostart articles for sale') }}</span>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col align="center">
              <v-checkbox
                :hide-details="true"
                class="input-field"
                v-model="currentConfig.general.enter_bidder_number"
                :label="$t('Require room bidder number')"
                @change="dataIsChanged = true"
              >
                <template #label>
                  <div class="text" style="color: #181818;">
                    <span>{{ $t('Require room bidder number') }}</span>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col align="center">
              <v-checkbox
                :hide-details="true"
                class="input-field"
                v-model="currentConfig.general.processAbsenteeBids"
                :label="$t('Process absentee bids')"
                @change="dataIsChanged = true"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col align="center" cols="12">
          <v-row>
            <v-col col="4">
              <v-text-field
                :label="$t('Bid by telephone available from')"
                style="cursor:auto"
                type="number"
                :rules="[validationRequiredRule(), validationPositiveNumberRule()]"
                @input="dataIsChanged = true"
                v-model="currentConfig.general.minimum_bid_for_telephone_bidding"
              >
                <template #message="{ messageData }">
                  {{ $t(messageData) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col col="4">
              <v-text-field
                :label="$t('Notification offset for articles on the wishlist')"
                style="cursor:auto"
                type="number"
                :rules="[validationRequiredRule(), validationPositiveNumberRule()]"
                @input="dataIsChanged = true"
                v-model="currentConfig.general.articleCountBeforeNotification"
              >
                <template #message="{ messageData }">
                  {{ $t(messageData) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col col="4">
              <v-text-field
                :label="$t('Minimum bid step for absentee bids')"
                style="cursor:auto"
                type="number"
                :rules="[validationRequiredRule(), validationPositiveNumberRule()]"
                @input="dataIsChanged = true"
                v-model="currentConfig.general.commissionBidMinimumBidStep"
              >
                <template #message="{ messageData }">
                  {{ $t(messageData) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="form-template-wrap pb-12">
      <v-row class="form-container justify-center">
        <v-col align="center" cols="12">
          <div class="form-title mb-5">{{ $t('Files') }}</div>
        </v-col>
        <v-col cols="12">
          <files-management :files="files" :loading="loading" :user-id="1" />
        </v-col>
      </v-row>
    </v-container>

    <!-- Action table -->
    <action-table
      :headers="headers"
      :tableData="tableData"
      :tableName="$t('Hotkey Configuration')"
      :handleAction="handleEdit"
      :handleAdd="handleAdd"
      class="table hide-table"
    />
    <div class="footer pb-8">
      <Footer />
    </div>
    <v-dialog width="842" v-model="isOpen" persistent>
      <v-card class="article-modal">
        <div class="close-btn-wrapper">
          <v-btn @click="closeModal()" class="close-btn">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="main-card">
          <v-container>
            <v-row align="center" class="form-container">
              <v-col align="center">
                <div class="form-title a2">{{ $t('Update Key') }}</div>
                <v-form ref="upsertForm">
                  <v-text-field
                    type="text"
                    :label="$t('Action')"
                    class="input-field"
                    placeholder=" "
                    :rules="[validationRequiredRule()]"
                    disabled
                    v-model="selectedKey.name"
                  >
                    <template #message="{ messageData }">
                      {{ $t(messageData) }}
                    </template>
                  </v-text-field>
                  <v-text-field
                    type="text"
                    :label="$t('Key')"
                    class="input-field"
                    placeholder=" "
                    :rules="[validationRequiredRule()]"
                    v-model="selectedKey.hotkey"
                  >
                    <template #message="{ messageData }">
                      {{ $t(messageData) }}
                    </template>
                  </v-text-field>
                  <v-row class="input-field" justify="space-between">
                    <v-btn @click="closeModal" large>
                      Cancel
                    </v-btn>
                    <v-btn large @click="saveKey">
                      <span class="a2">{{ $t('Save Key') }}</span>
                    </v-btn>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import FormTemplate from '@/components/form/index'
import ActionTable from '@/components/table/action-table'
import apiRequests from '@/api/apiRequests'
import { mapActions } from 'pinia'
import FilesManagement from '@/components/blocks/files-management'
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';

const ServerStatus = {
  ok: 1,
  uncheck: 0,
  error: -1,
  cors: -2,
  validation: 2,
}

export default {
  name: 'SystemSetting',
  components: {
    FormTemplate,
    ActionTable,
    FilesManagement
  },
  data: function () {
    return {
      message: null,
      isOpen: false,
      selectedKey: {},
      statusMessage: null,
      currentConfig: null,
      loading: null,
      dataIsChanged: false,
      ServerStatus,
      mediaStatus: ServerStatus.uncheck,
      generalForm: [
        {
          type: 'url',
          label: this.$t('Link to auction house website'),
          key: 'url_auction_house',
          required: true
        },
        {
          type: 'url',
          label: this.$t('Link to rules and regulations'),
          key: 'url_rules',
          required: true
        }
      ],
      streamForm: [
        {
          type: 'url',
          label: this.$t('Streaming server websocket'),
          key: 'url_stream',
          required: true
        },
        {
          type: 'url',
          label: this.$t('Streaming server http'),
          key: 'url_stream_http',
          required: true
        },
        {
          type: 'url',
          label: this.$t('Stream name'),
          key: 'url_stream_name',
          required: false
        },
        {
          type: 'select-value',
          label: this.$t('Stream type'),
          key: 'url_stream_type',
          options: [
            {
              title: this.$t('disabled'),
              value: 'stream_disabled',
            },
            {
              title: this.$t('Audio only'),
              value: 'stream_audio',
            },
            {
              title: this.$t('Audio and video'),
              value: 'stream_video',
            }
          ],
        },
      ],
      localizationForm: [
        {
          type: 'text',
          label: this.$t('Currency'),
          key: 'currency',
          required: true,
        },
        {
          type: 'url',
          label: this.$t('Media server url'),
          key: 'media_server_url',
          required: true,
        }
      ],
      headers: [
        {
          text: this.$t('Action'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('Hotkey'),
          align: 'end',
          value: 'hotkey',
        },
        {
          align: 'center',
          value: 'action',
          sortable: false,
          width: 50,
        },
      ],
      tableData: [
        {
          name: 'Knockdown',
          hotkey: 'Cmd+K',
        }
      ],
      files: [],
    }
  },
  async created () {
    await this.getSystemConfigs()
    await this.getUserFiles()

    // Turn an object
  },
  async mounted () {
    this.UPDATE_GLOBAL_STATE({
      key: 'appLoading',
      value: true,
    })
  },
  computed: {
    saveEnabled () {
      const { minimum_bid_for_telephone_bidding, articleCountBeforeNotification, commissionBidMinimumBidStep } = this.currentConfig.general // eslint-disable-line
      return this.currentConfig.general.url_auction_house && this.currentConfig.general.url_rules && this.currentConfig.general.url_stream && this.currentConfig.general.url_stream_http &&
        this.currentConfig.localization.currency && this.currentConfig.localization.media_server_url &&
        (minimum_bid_for_telephone_bidding >= 0 || !minimum_bid_for_telephone_bidding) && (articleCountBeforeNotification >= 0 || !articleCountBeforeNotification) && (commissionBidMinimumBidStep >= 0 || !commissionBidMinimumBidStep) // eslint-disable-line
    }
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'getUserSettings', 'SET_TEMP_ALERT']),
    validationRequiredRule: frontendValidations.required,
    validationPositiveNumberRule: frontendValidations.positiveNumber,
    closeModal() {
      this.isOpen = false;
      // this.clearData()
    },
    saveKey() {
      console.log('key saved')
      this.isOpen = false
    },
    async validateMedia () {
      this.mediaStatus = ServerStatus.uncheck
      try {
        const { data } = await apiRequests.testMediaServer({ url: this.currentConfig.localization.media_server_url })
        if (!data.data.connected) {
          this.mediaStatus = ServerStatus.error
          this.statusMessage = data.data.message
        } else {
          this.mediaStatus = ServerStatus.validation
          this.statusMessage = data.data.message
        }
      } catch (er) {
        this.loading = false
        this.mediaStatus = ServerStatus.error
        if (er.response) {
          this.statusMessage = er.response.data.error.message
        } else {
          this.statusMessage = er.message
        }
      }
    },
    handleDelete () {
      console.log('handleDelete', this.tableData)
    },
    handleEdit(itm) {
      console.log('handleEdit', itm)
      this.isOpen = true
      this.selectedKey = itm
      console.log(this.selectedKey)
      // this.tableData.shift()
    },
    handleAdd () {
      console.log('handleAdd')
    },
    async getSystemConfigs () {
      try {
        this.loading = true
        const { data } = await apiRequests.getConfigFields('db,localization,hotkeys,general')
        this.currentConfig = _.clone(data.data)
        let hotKeysList = []
        for (const prp in this.currentConfig.hotkeys) {
          hotKeysList.push({ name: prp, hotkey: this.currentConfig.hotkeys[prp] })
        }
        this.tableData = hotKeysList
        this.loading = false
      } catch (e) {
        this.error = true
        this.loading = false
      }
    },
    async saveConfigs () {
      try {
        this.currentConfig.general.minimum_bid_for_telephone_bidding = +this.currentConfig.general.minimum_bid_for_telephone_bidding
        this.currentConfig.general.articleCountBeforeNotification = +this.currentConfig.general.articleCountBeforeNotification
        this.currentConfig.general.commissionBidMinimumBidStep = +this.currentConfig.general.commissionBidMinimumBidStep
        this.mediaStatus = ServerStatus.uncheck
        this.loading = true
        await apiRequests.updateConfigs(this.currentConfig)
        await this.getUserSettings()
        this.loading = false
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Values changed successfully') })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error changing values') })
        this.error = true
        this.loading = false
      }
    },
    async getUserFiles() {
      try {
        this.loading = true
        const { data } = await apiRequests.getFilesBackup()
        this.files = data.data.files
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
}
</script>
<style>
  .hide-table {
    display:none;
  }
</style>
