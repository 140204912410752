<template>
  <v-dialog
    v-model="auctionSignupModal"
    content-class="bid-modal-content"
    @keydown.esc="closeModal"
    width="650"
    @keydown.enter="!disabledSignupButton || (requireBidLimit && isFormValid) ? sendSignup() : {};"
    persistent
  >
    <v-card class="pa-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <v-card-title class="justify-center">
        <span class="headline">{{ $t('Confirm sign up') }}</span>
      </v-card-title>
      <v-form ref="auctionSignupForm" v-model="isFormValid" @submit.prevent>
        <div v-if="requireBidLimit">
          <p class="bid-modal-text"><span>{{ $t('user_bid_limit_text1') }}</span></p>
          <v-text-field
            type="number"
            @input="inputBidLimitHandler"
            :rules="[validationRequired(), validationPositiveNumber(), validationWholeNumber()]"
            :label="$t('user_bid_limit_input1_placeholder')"
            persistent-hint
            class="input-field"
            single-line
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
          <div class="info-text full-width">
            <v-icon>fa-info-circle</v-icon>
            <p>
              {{ $t('user_bid_limit_info1_text') }}
            </p>
          </div>
        </div>
        <div v-else>
          <p class="text-center">{{ $t('Do you really want to sign up for that auction?') }}</p>
        </div>
        <div class="info-text mt-4">
          <v-icon>fa-info-circle</v-icon>
          <p>
            {{ $t('Please note') }}<br>
            <span v-if="appSettings.commission.live !== 0">{{ $t('auction_signup_live_bidding_commission', {tAuctionCommissionRate: selectedAuctionData?.surcharge, tLiveBiddingRate: appSettings.commission.live}) }}</span>
            <span v-else>{{ $t('auction_signup_live_bidding_commission', {tAuctionCommissionRate: selectedAuctionData?.surcharge}) }}</span><br/>
            <span v-if="showTotalCommissionCosts">{{totalCostsText}}</span>
          </p>
        </div>
        <v-checkbox
          v-if="receiveAuctionStartReminderEmail"
          hide-details
          class="input-field"
          v-model="receiveAuctionStartReminderEmail"
          :label="$t('Receive a reminder email before the auction starts')"
        />
        <div class="text-center mt-6">
          <v-btn
            class="btn primary-btn success"
            :disabled="disabledSignupButton || (requireBidLimit && !isFormValid)"
            @click="sendSignup"
            :loading="loading"
          >
            <v-icon class="mr-2">fa-envelope</v-icon>
            {{ $t('Submit') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { localFeatures, timeouts } from '@/utils/constants'
import _ from 'lodash'
import {mapActions, mapState} from 'pinia';
import dispatcher from '@/api/dispatch';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import { euNumbers } from '@/services/i18n';

export default {
  name: 'UserAuctionSignupModal',
  props: {
    userId: {
      type: Number,
      default: null
    },
    auctionId: {
      type: Number,
      default: null
    },
    requireBidLimit: {
      type: Boolean,
      default: false
    },
    auctionStatus: {
      type: String,
      default: ''
    },
    auctionStartDate: {
      type: String | Date,
      default: ''
    },
    selectedAuctionData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      auctionSignupModal: false,
      bid: null,
      disabledSignupButton: true,
      loading: false,
      isFormValid: false,
      receiveAuctionStartReminderEmail: true,
      showTotalCommissionCosts: localFeatures.showTotalCommissionCosts
    }
  },
  computed: {
    ...mapState(useRootStore, ['appSettings']),
    auctionStartReminderNotYetSent() {
      return this.moment(this.auctionStartDate).subtract(this.appSettings.minutesToNotifyUsersBeforeAuction, "minutes").isAfter()
    },
    totalCostsText() {
      const multiplier = (this.selectedAuctionData?.tax > 0) ? ((this.selectedAuctionData?.tax / 100) +1) : 1
      const costs = ((this.selectedAuctionData?.surcharge + this.appSettings?.commission.live) * multiplier).toFixed(2)
      return this.$t('auction_signup_live_bidding_total_costs', {tLiveBiddingTotalCosts: costs})
    },
  },
  watch: {
    // evaluate every time the modal is opened
    auctionSignupModal(val) {
      if (val) {
        this.receiveAuctionStartReminderEmail = this.auctionStatus === 'preparing' && this.auctionStartReminderNotYetSent
      }
    },
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    validationPositiveNumber: frontendValidations.positiveNumber,
    validationRequired: frontendValidations.required,
    validationWholeNumber: frontendValidations.wholeNumber,
    euNumbers,
    closeModal() {
      this.$refs.auctionSignupForm.reset()
      this.bid = null
      this.disabledSignupButton = true
      this.auctionSignupModal = false
      this.receiveAuctionStartReminderEmail = true
    },

    openModal() {
      this.disabledSignupButton = this.requireBidLimit
      this.auctionSignupModal = true
    },

    async sendSignup() {
      try {
        this.loading = true
        if (this.requireBidLimit) {
          await dispatcher.requestAuctionSignup(this.auctionId, this.userId, this.bid, this.receiveAuctionStartReminderEmail)
        } else {
          await dispatcher.requestAuctionSignup(this.auctionId, this.userId, null, this.receiveAuctionStartReminderEmail)
        }

        this.$swal.fire({
          position: 'center',
          icon: 'success',
          title: this.$t('Almost done'),
          html: this.$t('We have received your sign up request for the auction.You will be instantly notified via email once the request is confirmed.'),
          showConfirmButton: false,
          timer: timeouts.closeSweetalert
        })
        this.$emit('refresh')
        this.loading = false
        this.closeModal()
      } catch (e) {
        this.loading = false
        if (e.data?.error && e.data.errors[0].errorShortText === 'admin.auction.signup.not.possible') {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('Currently, signup for the auction is not possible'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
        } else if (e.data?.error && e.data.errors[0].errorShortText === 'user.profile.incomplete') {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('Your profile is incomplete and is missing required information for this functionality. Please complete your profile and try again'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
        } else {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error to sign up for that auction. Please try again later.') })
        }
        this.closeModal()
      }
    },
    inputBidLimitHandler: _.debounce(function (e) {
      this.bid = +e
      this.disabledSignupButton = !this.isFormValid
    }, 100),
  }
}
</script>

<style scoped lang="scss">
.info-text {
  @media(max-width: 760px) {
    display: flex;
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
