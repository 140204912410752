import dispatcher from '@/api/dispatch';

/**
 * Get biddernumbers for a user sorted by id ascending
 * @param {number} userId - The user id
 * @return {Promise<any>} - Promise containing the bidder numbers
 */
export async function getBidderNumbersFromUser(userId: number): Promise<any> {
  const result = await dispatcher.getBidderNumbers(userId);

  let r = result.data.data[0].userToAuctionUser
  r.sort((a, b) => b.id - a.id);
  return r;
}
