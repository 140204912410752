import { defineStore } from 'pinia'
import _ from 'lodash';
import Vue from 'vue';
import dispatcher from '@/api/dispatch';
import { BidderNumberType, Role } from '@/utils/constants';
import { i18n } from '@/main';
import { getBidderNumbersFromUser } from '@/services/user';
import apiRequests from '@/api/apiRequests';
import socketRequests from '@/api/socketRequests';
import useRootStore from '@/stores/rootStore';
import { IArticle, IUser, IUserJoinedAuctions } from '@/api/types';

interface IAuctionStoreState {
  currentAuction: Record<string, any>,
  currentAuctionArticles: Array<IArticle>
  knockdowns: string
  loadingModal: boolean
  loadingType: string,
  selectedArticle: IArticle,
  showPushNotiTooltip: boolean,
  stopLoadingModal: boolean,
  jumpLoadingModal: boolean,
  askJumpId: number,
  reauctionLoadingModal: boolean,
  footerCrawler: boolean,
  userData: IUser,
  reOffered: boolean,
  joinModal: boolean,
  articlesListActive: boolean,
  isAdminLogout: boolean,
  joinedLiveAuctions: IUserJoinedAuctions
}

const useAuctionStore = defineStore('auctionStore', {
  state (): IAuctionStoreState {
    return {
      currentAuction: {} as any, // the current active live auction
      currentAuctionArticles: [], // all articles from the current active live auction
      knockdowns: '', // knockdowns? (not used)
      loadingModal: true, // flag to indicate if the loading modal during the auction should be displayed (i.e. "The auction is paused")
      loadingType: 'loading', // the type of the message to display in the loading modal
      selectedArticle: null, // currently sold article on the live auction
      showPushNotiTooltip: false, // flag to indicate if the push notification for the bid warning ("the article is about to be sold. Bid now") should be displayed
      stopLoadingModal: false, // flag to indicate if the stop auction modal is displayed on the admin auction view
      jumpLoadingModal: false, // flag to indicate if the jump to article modal is displayed on the admin auction view
      askJumpId: null, // the article id if the article to be jumped to
      reauctionLoadingModal: false, // flag to indicate if the reauction article modal is displayed on the admin auction view
      footerCrawler: false, // ? (not used)
      userData: null, // the current user data i.e. registered count
      reOffered: false, // flag to indicate if an article is re-offered (called again after knockdown)
      joinModal: false, // flag to indicate if the auction join modal is displayed
      articlesListActive: true, // flag to indicate if the article slider is displayed on the admin auction view
      isAdminLogout: false, // flag to indicate if the admin logged out during the auction (this is, clicked the logout button)
      joinedLiveAuctions: null // running live auction join state for the current user
    };
  },
  getters: {
    // Current article to be sold
    ongoingArticle: (state: any) => {
      if (_.isEmpty(state.currentAuction.ongoingArticle)) {
        return {}
      } else {
        if (state.selectedArticle === null) {
          return state.currentAuction.ongoingArticle
        } else {
          return state.currentAuctionArticles[state.selectedArticle]
        }
      }
    },

    // Get current highest bid
    currentHighestBid: (state: any) => {
      if (_.isEmpty(state.currentAuction.ongoingArticle)) {
        return { value: 'N/A', id: null, bidder_number: 0, type: null }
      } else { // If this is no bid yet, return the limit step
        return state.currentAuction.highestBid?.value
          ? { value: state.currentAuction.highestBid.value, id: state.currentAuction.highestBid.user_id, bidder_number: state.currentAuction.highestBid.bidder_number, type: state.currentAuction.highestBid.type }
          : { value: 0, id: null, articleValue: 0, bidder_number: 0, type: null }
      }
    },

    // Get next bid amount
    nextBidAmount: (state: any) => {
      const { ongoingArticle, highestBid, bidStep, default_bid_step: defaultBidStep } = state.currentAuction;
      if (_.isEmpty(ongoingArticle)) {
        return 0;
      } else {
        if (!highestBid || !highestBid.value) {
          return ongoingArticle.last_ask_price > 0 ? ongoingArticle.last_ask_price : ongoingArticle.limit
        } else {
          const step = bidStep ? bidStep.bid_step : defaultBidStep
          const startVal = bidStep ? bidStep.start : 0
          const fullDivided = (highestBid.value - startVal) % step
          const expr = Math.ceil((highestBid.value - startVal) / step)
          const higherInTimes = fullDivided === 0 ? expr + 1 : expr
          return highestBid.value >= ongoingArticle.last_ask_price ? startVal + step * higherInTimes : ongoingArticle.last_ask_price
        }
      }
    },

    // Get ncurrent bid step
    currentStepValue: (state: any) => {
      return state.currentAuction.bidStep ? state.currentAuction.bidStep : { bid_step: state.currentAuction.default_bid_step }
    },

    // Get current user data
    usersData: (state: any) => {
      return state.currentAuction.userData
    },

    // Get bid history
    bidHistory(state: any) {
      return state.currentAuction.bidHistory
        ? state.currentAuction.bidHistory.map((item: any, index: number) => {
          item.bidder_number = item.bidder_number ? _.padStart(item.bidder_number, 4, '0') : '----'
          return item;
        })
        : []
    }
  },
  actions: {
    CHANGE_STATE(object: any) {
      // Array
      if (object.length > 0) {
        object.forEach((item: any) => {
          this[item.key] = item.value
        })
      } else {
        this[object.key] = object.value
      }
    },
    UPDATE_ARTICLE_STATUS(data: any) {
      let index = _.findIndex(this.currentAuctionArticles, ['id', data.id]);
      Vue.set(this.currentAuctionArticles, index, data.data)
    },
    TOGGLE_CRAWLER(data: boolean) {
      this.footerCrawler = data
    },
    SET_USER_DATA(data: any) { //TODO redundant?
      this.userData = data
    },
    SET_IS_ADMIN_LOGOUT (data: any) {
      this.isAdminLogout = data
    },
    /**
     * Get the current active auction data and update the store
     * @return {Promise<any>} - Promise containing the current active auction data
     */
    async getCurrentActiveAuction () {
      const rootStore = useRootStore();
      try {
        let result = await dispatcher.getCurrentActiveAuction();

        let { data } = result;

        // Update current auction
        const auctionData = _.isEmpty(data.data) ? {} : data.data;
        this.CHANGE_STATE({key: 'currentAuction', value: auctionData});
        if (_.isEmpty(data.data)) this.CHANGE_STATE({key: 'currentAuctionArticles', value: []})
        return data
      } catch (e: any) {
        if (e.data?.error && this.userData.role === Role.admin) {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: `${i18n.t('An error occured')}:\n
            ${i18n.t('Backend error message')}: ${i18n.t(e.data.errors[0].errorShortText)}`
          });
        }
        this.CHANGE_STATE({ key: 'currentAuction', value: {} });
        return e.data
      }
    },
    async getAuctionArticles (payload: any) {
      const rootStore = useRootStore();
      try {
        let userBidderNumbers
        if (this.userData.role === Role.user) {
          const bidderNumbers = await getBidderNumbersFromUser(this.userData.id)
          userBidderNumbers = bidderNumbers.filter(el => el.auction_id === payload.auctionId && el.type === BidderNumberType.live && el.user_status === 'unlocked')
        }

        let knockdownsResp
        if (this.userData && (this.userData.role === Role.admin || (this.userData.role === Role.user && !_.isEmpty(userBidderNumbers)))) {
          knockdownsResp = (await dispatcher.getKnockdownsArticleSlider(payload.auctionId)).data
        } else {
          knockdownsResp = null
        }

        if (window.localStorage.getItem('currentAuctionArticles') && Vue.$cookies.get('liveAuctionCache') && payload.isUserFE) {
          let auctionNumber = payload.auctionCode
          let mediaServer = payload.mediaServer
          const articlesWithImages = JSON.parse(<string>localStorage.getItem('currentAuctionArticles')).map((itm: any) => {
            const updatedVal = knockdownsResp ? knockdownsResp.data.find((el: any) => el.article_id === itm.id) : null
            let articleNumber = itm.number
            let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
            if (updatedVal) return { ...itm, image: imageUrl, ...updatedVal }
            return { ...itm, image: imageUrl }
          })
          this.CHANGE_STATE({ key: 'currentAuctionArticles', value: articlesWithImages });
        } else {
          try {
            const result = await apiRequests.getAuctionArticles(payload.auctionId);

            let { data } = result;

            // Update current auction
            if (payload.isUserFE) {
              const cookiesData = {
                ongoingAuctionID: payload.auctionId,
                articleCacheLastUpdated: new Date()
              }
              Vue.$cookies.set('liveAuctionCache', JSON.stringify(cookiesData))
              localStorage.setItem('currentAuctionArticles', JSON.stringify(data.data))
            }
            let auctionNumber = payload.auctionCode
            let mediaServer = payload.mediaServer
            const articlesWithImages = data.data.map((itm: any) => {
              const updatedVal = knockdownsResp.data.find((el: any) => el.article_id === itm.id)
              let articleNumber = itm.number
              let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
              if (updatedVal) return { ...itm, image: imageUrl, ...updatedVal }
              return { ...itm, image: imageUrl }
            })
            this.CHANGE_STATE({ key: 'currentAuctionArticles', value: articlesWithImages || [] });
            return data
          } catch (e: any) {
            this.CHANGE_STATE({ key: 'currentAuctionArticles', value: {} });
            if (e.data?.error) {
              rootStore.SET_TEMP_ALERT({
                flavor: 'error',
                content: `${i18n.t('An error occured')}:\n
                ${i18n.t('Backend error message')}: ${i18n.t(e.data.errors[0].errorShortText)}`
              });
              return e.data
            }
          }
        }
      } catch (e) {
        console.log('No articles')
      }
    },
    async startAuction (payload: any) {
      const rootStore = useRootStore();
      try {
        rootStore.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });

        let result = await apiRequests.startAuction(payload.auctionId);
        let { data } = result;

        await this.getCurrentActiveAuction();

        return data
      } catch (e) {
        return e
      } finally {
        rootStore.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    async jumpToArticle (auction: any) {
      const rootStore = useRootStore();
      try {
        rootStore.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });

        let result = await dispatcher.jumpToArticle(auction.auctionId, auction.articleId);
        let { data } = result;

        if (auction.prevId) {
          const prevArticle = this.currentAuctionArticles.find((item: any) => item.id === auction.prevId)
          prevArticle.sold_price = auction.sold_price
          prevArticle.bidder_number = auction.bidder_number
          prevArticle.live_bidder = auction.live_bidder
          if (prevArticle.sold_price > 0 && prevArticle.last_ask_price > 0) {
            prevArticle.status = '0'
          } else if (prevArticle.sold_price === 0) {
            if (auction.articleIsStarted) {
              prevArticle.status = '4'
            } else {
              prevArticle.status = '3'
            }
          }
        }

        return data;
      } catch (e: any) {
        if (e.data?.error) {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: `${i18n.t('An error occured')}:\n
            ${i18n.t('Backend error message')}: ${i18n.t(e.data.errors[0].errorShortText)}`
          });
          return e.data;
        }
      } finally {
        rootStore.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    async doAction (payload: any) {
      const rootStore = useRootStore();
      try {
        rootStore.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result = await apiRequests.doAction(payload)

        let { data } = result;

        // Get new data
        // Update current auction
        if (payload.type === 'ask') {
          this.CHANGE_STATE([{
            key: 'currentAuction',
            value: {
              ...this.currentAuction,
              ongoingArticle: {
                ...this.currentAuction.ongoingArticle,
                last_ask_price: payload.value
              }
            }
          }]);
        } else if (payload.type !== 'sellItem' || payload.type !== 'bidWarning') {
          await this.getCurrentActiveAuction();
        }
        return data
      } catch (e: any) {
        if (e.data?.error) {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: `${i18n.t('An error occured')}:\n
            ${i18n.t('Backend error message')}: ${i18n.t(e.data.errors[0].errorShortText)}`
          });
          return e.data
        }
      } finally {
        rootStore.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    async doBid (uploadData: any) {
      const rootStore= useRootStore();
      delete uploadData.preventUpdateCallBack; //TODO unused, has no effect

      try {
        let result = await apiRequests.doBid(uploadData);

        let { data } = result;

        console.log(`REST bid sent with value ${uploadData.bid}`)
        return data
      } catch (e: any) {
        if (e.data?.error && (!uploadData.isUser || (uploadData.isUser  && (e.data.errors[0].errorShortText === 'bid.plausibility.bid.again' || e.data.errors[0].errorShortText === 'user.bid.bidlimit.reached')))) {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: `${i18n.t(e.data.errors[0].errorShortText)}`
          });
        }
        return false // TODO return null, change in admin view
      }
    },
    async doSocketBid (uploadData: any) {
      const rootStore= useRootStore();

      try {
        await socketRequests.doSocketBid(uploadData.auctionId, uploadData.articleId, uploadData.bid, uploadData.isUser, uploadData.isBtr);

        console.log(`Socket bid sent with value ${uploadData.bid}`)
        return true;
      } catch (e: any) {
        if (e.data?.error && (!uploadData.isUser || (uploadData.isUser && (e.data.errors[0].errorShortText === 'bid.plausibility.bid.again' || e.data.errors[0].errorShortText === 'user.bid.bidlimit.reached')))) {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: `${i18n.t(e.data.errors[0].errorShortText)}`
          });
        }
        return false;
      } finally {
        if (uploadData.callback) {
          uploadData.callback();
        }
      }
    },
    /**
     * Get Knockdowns for an auction and update the store
     * @param {number} auctionId - The auction id
     * @return {Promise<any>} - Promise containing the knockdowns of the auction or exception data
     */
    async getKnockdowns (auctionId: number) {
      try {
        let result = await apiRequests.getKnockdowns(auctionId);

        let { data } = result;

        this.CHANGE_STATE({ key: 'knockdowns', value: data.data || '' });
        return data;
      } catch (e: any) {
        this.CHANGE_STATE({ key: 'knockdowns', value: '' });
        return e.data
      }
    },
    /**
     * Add an article to an auction and update the store
     * @param {any} data - payload
     * @return {Promise<any>} - Promise containing the created article or null on error
     */
    async addArticle (data: any) {
      const rootStore = useRootStore();
      const { auctionId, article } = data;

      try {
        const { data } = await apiRequests.createArticle(auctionId, {
          ...article,
          auction_id: auctionId
        });

        rootStore.ADD_AUCTIONS_FETCHED_ARTICLE({
          auctionID: auctionId,
          article: data.data
        });
        return data.data;
      } catch (e: any) {
        console.log(e)
        if (e.data?.error && e.data.errors[0].errorShortText === 'admin.article.exist') {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: i18n.t('The article number already exists')
          });
        } else {
          rootStore.SET_TEMP_ALERT({
            flavor: 'error',
            content: i18n.t('There was an error creating the article')
          });
        }
        return null;
      }
    },
    /**
     * Update an article of an auction and update the store
     * @param {any} data - payload
     * @return {Promise<any>} - Promise containing the updated article or null on error
     */
    async updateArticle (data: any) {
      const rootStore = useRootStore();
      const { auctionId, article } = data;

      try {
        const { data } = await apiRequests.updateArticle(auctionId, article.id, article);

        rootStore.UPDATE_AUCTIONS_FETCHED_ARTICLE({
          auctionID: auctionId,
          article: data.data
        });
        return data.data;
      } catch (e: any) {
        rootStore.SET_TEMP_ALERT({
          flavor: 'error',
          content: i18n.t('There was an error changing values')
        });
        return null;
      }
    },
    /**
     * Get the auction joined state for current user
     * @return {Promise<any>} - Promise containing the updated article or null on error
     */
    async getAuctionJoinedState(): Promise<IUserJoinedAuctions> {
      try {
        let result = await dispatcher.getAuctionJoinStatus();

        this.CHANGE_STATE({key: 'joinedLiveAuctions', value: result});
        return result;
      } catch (e: any) {
        this.CHANGE_STATE({ key: 'joinedLiveAuctions', value: null});
        return null;
      }
    }
  }
})

export default useAuctionStore
