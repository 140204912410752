<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div v-if="appLoading">
      <v-row no-gutters class="auction-wrapper" v-if="ongoingArticle">
        <v-col cols="12" class="article-wrapper mobile-view" style="height: 100%" sm="12" xs="12">
          <v-row class="title-wrapper" no-gutters>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <div class="title">{{ articleTitle }}</div>
            </v-col>
            <v-row>
              <v-col class="price-wrapper no-gutters">
                <span class="light-title">{{ $t('presentationView.lot') }}</span>&nbsp;
                <span class="price-value">#{{ getArticleNumber }}</span>
              </v-col>
              <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
                <span class="light-title">{{ $t('presentationView.limit') }}</span>&nbsp;
                <span class="price-value">{{ currency }}{{ euNumbers(ongoingArticle.limit) }}</span>
              </v-col>
              <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.estimated_price">
                <span class="light-title">{{ $t('presentationView.estimate') }}</span>&nbsp;
                <span class="price-value">{{ currency }}{{ ongoingArticle.estimated_price }}</span>
              </v-col>
              <div class="extra-spacer" v-if="!ongoingArticle.estimated_price || !ongoingArticle.estimated_price" />
            </v-row>
          </v-row>
        </v-col>
        <v-col class="image-slider-wrapper" cols="12" style="height: 100%" lg="7" md="7" sm="12" xs="12">
          <VueSlickCarousel lazyLoad="ondemand" :arrows="true" :key="articleGallery.length" :dots="false" class="slider-container" v-if="ongoingArticle && articleGallery.length">
            <template #prevArrow>
              <div class="custom-arrow">
                <v-icon class="arrow-left">fa-angle-left</v-icon>
              </div>
            </template>

            <template #nextArrow>
              <div class="custom-arrow">
                <v-icon class="arrow-right">fa-angle-right</v-icon>
              </div>
            </template>
            <div class="image-item" v-for="itm in articleGallery" :key="itm.articleNumber">
              <v-img class="article-image" :contain="true" :src="itm.image" height="60vh" width="80%" />
            </div>
          </VueSlickCarousel>
        </v-col>

        <v-row class="article-description mobile-view" no-gutters>
          <div>
            {{ articleDescription }}
          </div>
        </v-row>

        <v-col style="height: 100%;" class="article-wrapper" lg="5" md="5" sm="12" xs="12">
          <v-row class="title-wrapper" no-gutters>
            <v-col lg="12" md="12" sm="12" xs="12">
              <div class="title">{{ articleTitle }}</div>
            </v-col>
            <v-row class="justify-start mb-0 mt-4">
              <v-col class="price-wrapper no-gutters">
                <span class="light-title">{{ $t('presentationView.article') }}</span>&nbsp;
                <span class="price-value">#{{ ongoingArticle.number_optional || _.padStart(ongoingArticle.number, 4, '0') }}</span>
              </v-col>
              <!--              <br v-if="ongoingArticle.estimated_price && ongoingArticle.estimated_price != null">-->
              <!--              <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit && ongoingArticle.limit != null">-->
              <!--                <span class="light-title" v-translate>Limit</span>&nbsp;-->
              <!--                <span class="price-value">{{currency}}{{ euNumbers(ongoingArticle.limit) }}</span>-->
              <!--              </v-col>-->
              <!--              <br v-if="ongoingArticle.limit && ongoingArticle.limit != null">-->
              <!--              <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.estimated_price && ongoingArticle.estimated_price != null">-->
              <!--                <span class="light-title" v-translate>Estimated price</span>&nbsp;-->
              <!--                <span class="price-value">{{currency}}{{ ongoingArticle.estimated_price }}</span>-->
              <!--              </v-col>-->
            </v-row>
          </v-row>

          <v-row class="mb-6" justify="start" no-gutters align="start" v-if="ongoingArticle.status === '2' || ongoingArticle.status === '0'">
            <v-col md="6" class="current-highest-bid-block">
              <p class="mb-1 current-highest">{{ $t('presentationView.currentHighestBid') }}</p>
              <p class="bold-500 bid-value mb-2"><span class="currency">{{ currency }}</span> {{ euNumbers(currentHighestBid.value) }}</p>
              <template v-if="currencies">
                <p class="bold-500 bid-value mb-2" v-for="item in currencies" :key="item.key">
                  <span class="currency">{{ item.symbol }}</span> {{ convertedValue(currentHighestBid.value, item.ratio) }}
                </p>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Todo : We need to change this to component -->
      <v-overlay class="custom-back-drop status-modal" v-if="renderComponent && loadingModal" light>
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <span class="loading-message">{{ getLoadingMessage() }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop status-modal" v-if="showNetworkError">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br>
              <span class="loading-message">{{ getLoadingMessage() }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop no-ongoing-article-modal" v-if="currentAuction.status === 'started' && _.isEmpty(ongoingArticle)">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br/>
              <span class="loading-message">{{ $t('No ongoing article found in the live auction. Please wait') }}</span>
            </div>
          </div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import SocketClient from '@/utils/socket'
import { mapActions, mapState } from 'pinia'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import apiRequests from '@/api/apiRequests'
import SocketHandlers, { SocketPostHandleEmitterEvent } from '@/api/socketHandlers';
import tinykeys from '@/utils/tiny-keys';
import _ from 'lodash'
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { SocketConnectionStatus } from '@/utils/constants';
import { convertedValue, euNumbers, getTranslatedText } from '@/services/i18n';

export default {
  name: 'AuctionViewer',
  components: {
    VueSlickCarousel
  },
  async created() {
    // Get current language from url param
    // TODO make this work, even if en is passed the translations are not in english
    if (this.$route.query && this.$route.query.lang && this.appLocalization.i18n.availableLanguages.find(el => el.lang === this.$route.query.lang)) {
      this.APP_GLOBAL_STORE_LANG(this.$route.query.lang)
    }
    try {
      // Listener
      this.setUpSocket()

      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      let rs = await this.getCurrentActiveAuction()
      // Cant connect to server or no live auction started
      if (!rs || _.isEmpty(rs.data)) {
        this.CHANGE_STATE([
          {
            key: 'loadingModal',
            value: true,
          },
          {
            key: 'loadingType',
            value: 'noLive',
          }])
      }
      await this.getCurrencies()
      // Hide modal if auction is available
      if (rs.data) {
        if (rs.data.status === 'paused') {
          if (this.showNetworkError) {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: true,
              },
              {
                key: 'loadingType',
                value: 'networkPause',
              }])
          } else {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: true,
              },
              {
                key: 'loadingType',
                value: 'paused',
              }])
          }
        }

        if (rs.data.status === 'started') {
          this.CHANGE_STATE([
            {
              key: 'loadingModal',
              value: false,
            },
            {
              key: 'loadingType',
              value: 'started',
            }
          ])
        }
      }

      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })

      if (this.ongoingArticle.id) {
        this.articleGallery = this.ongoingArticle.images
      } else {
        this.articleGallery = []
      }

      this.removeListener = tinykeys(window, {
        l: () => {
          this.logoutHandler()
        }
      })
    } catch (e) {
      console.log(e)
      this.UPDATE_GLOBAL_STATE([
        {
          key: 'error',
          value: true,
        },
        {
          key: 'errorMessage',
          value: e.message,
        },
      ])
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })
    }
  },
  beforeDestroy () {
    const emitter = SocketHandlers.getEmitter();
    emitter.off(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);
    //clearInterval(this.getStatusLink);
    this.removeListener();
  },
  async destroyed() {
    if (this.socket.disconnect) {
      this.socket.disconnect()
    }
  },
  data: () => ({
    slideModel: null,
    items: [],
    showNotiTooltip: false, // When user got higher bid
    notificationMessage: '',
    currentMobilePage: 1,
    showMobileArticleModal: false,
    articleGallery: [],
    showNetworkError: false,
    isArticleSoldOut: false,
    isLastArticleSoldOut: false,
    disconnected: false,
    cachedCurrentArticle: null,
    renderComponent: true,
    showPushNotiTooltipTest: true,
    //getStatusLink: null
  }),
  watch: {
    async ongoingArticle() {
      if (this.currentAuction && this.currentAuction.id) {
        if (this.ongoingArticle.id !== this.cachedCurrentArticle) {
          this.cachedCurrentArticle = this.ongoingArticle.id
          this.articleGallery = this.ongoingArticle.images
        }
      }
    },
    currentAuction: {
      deep: true,
      handler() {
        this.isArticleSoldOut = (this.currentAuction && this.ongoingArticle && this.ongoingArticle.status === '0');
      }
    },
    socketConnectionStatus: {
      handler() {
        this.showNetworkError = this.socketConnectionStatus !== SocketConnectionStatus.connected;
      }
    },
    async isOffline () {
      if (!this.isOffline) {
        this.disconnected = false
        this.showNetworkError = false
        await this.getCurrentActiveAuction()
      } else {
        this.disconnected = true
        this.showNetworkError = true
      }
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'loadingModal', 'loadingType', 'showPushNotiTooltip', 'isAdminLogout', 'ongoingArticle', 'currentHighestBid', 'nextBidAmount', 'currentStepValue']),
    ...mapState(useRootStore, ['userProfile', 'notificationList', 'showBottomNavigation', 'appLoading', 'productCataloguePredefinedValue', 'showAuctionOverview', 'appFeatures', 'isOffline', 'appStoreLang', 'appSettings', 'appLocalization', 'isMobile', 'socket', 'socketConnectionStatus']),
    articleTitle() {
      return getTranslatedText(this.ongoingArticle, 'name', this.appStoreLang)
    },
    articleDescription() {
      return getTranslatedText(this.ongoingArticle, 'description', this.appStoreLang)
    },
    currency() {
      return this.appLocalization.currency
    },
    getArticleNumber() {
      return this.ongoingArticle.number_optional || this.ongoingArticle.number
    },
    isMediaReady() {
      return !!((this.userProfile.id && this.appLocalization))
    },
    isArticleReady() {
      const { ongoingArticle } = this.currentAuction
      if (ongoingArticle) {
        return ongoingArticle.last_ask_price === 0 || this.userProfile.id === this.currentHighestBid.id || ongoingArticle.status !== '2'
      }
      return false
    },
    itemThumbnail() {
      return (this.articleGallery.length) ? this.articleGallery[0].image : ''
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['getCurrentActiveAuction', 'CHANGE_STATE', 'getAuctionArticles', 'CHANGE_STATE', 'SET_IS_ADMIN_LOGOUT']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_LOGOUT_CLICKED', 'APP_GLOBAL_STORE_LANG', 'getNotification', 'markReadNotification', 'logout', 'SET_SOCKET_DATA']),
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    convertedValue,
    euNumbers,
    startAuctionPostHandleEmitterEvent(data) {
      this.getAuctionArticles({ auctionId: this.currentAuction.id })
      this.CHANGE_STATE([
        {
          key: 'loadingModal',
          value: false,
        },
        {
          key: 'loadingType',
          value: 'started',
        }
      ])
    },
    resumeAuctionPostHandleEmitterEvent(data) {
      this.SET_IS_ADMIN_LOGOUT(false)
    },
    setUpSocket() {
      // connect to user namespace
      const { socket } = SocketClient.getInstance();
      const emitter = SocketHandlers.getEmitter();

      // Start auction (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);

      // Resume (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);

      this.SET_SOCKET_DATA(socket)
    },
    getLoadingMessage() {
      if (this.showNetworkError) {
        return this.$t('auctionLoadingState.userNetworkIssue')
      }
      return this.$t('auctionLoadingState.' + this.loadingType)
    },
    async getCurrencies() {
      const { data } = await apiRequests.getSystemCurrencies()
      this.currencies = data.data.currencies.items
    },
    logoutHandler() {
      this.SET_LOGOUT_CLICKED(true);
      this.logout()
    }
  }
}
</script>
<style lang="scss" scoped>
.auction-container {
  .article-wrapper {
    .title {
      font-size: 70px !important;
      line-height: 1.1;
    }
    .price-wrapper {
      margin-top: 40px;
      .light-title {
        font-size: 50px;
        line-height: 1.1;
      }
      .price-value {
        margin-top: 15px;
        font-size: 58px;
        line-height: 1.1;
      }
    }
    .details-title {
      font-size: 28px;
    }
    .article-description {
      font-size: 30px;
      line-height: 1.3;
    }
  }
  .image-slider-wrapper,
  .slider-container {
    max-height: 60vh !important;
  }
  .current-highest-bid-block {
    margin-top: 25px;
    .current-highest {
      font-size: 34px;
    }
    .bid-value {
      display: block;
      margin-top: 35px;
      font-size: 44px;
      .currency {
        display: inline-block;
        width: 35px;
      }
    }
  }
  .slick-prev, .slick-next {
    display: none;
  }
  .slider-container {
    max-width: initial;
  }
}
.auction-wrapper {
  padding-top: 40px;
}
.info-text {
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 100%;
  margin: 0 auto 15px;
  background: #87A4CF;
  padding: 11px 24px 11px 19px;
  color: #264D86;
  border-radius: 8px;
  @media(max-width: 760px) {
    display: none;
  }
  .v-icon {
    font-size: 27px;
    color: #264D86;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    margin: 0 0 0 15px;
  }
}
</style>
